import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../../styles/components/UploadMedia.css';

const FOLDER_PATHS = {
    AUDIO: 'audio/128kbps',
    VIDEO: 'video'
};

export default function UploadMedia() {
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [mediaList, setMediaList] = useState({
        audio: [],
        video: []
    });
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [audioOpen, setAudioOpen] = useState(true);
    const [videoOpen, setVideoOpen] = useState(true);

    useEffect(() => {
        fetchMediaList();
    }, []);

    const fetchMediaList = async () => {
        try {
            const [audioResponse, videoResponse] = await Promise.all([
                axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/media?folder=${FOLDER_PATHS.AUDIO}`,
                    { withCredentials: true }
                ),
                axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/media?folder=${FOLDER_PATHS.VIDEO}`,
                    { withCredentials: true }
                )
            ]);


            let audioFiles = audioResponse.data.blobs.filter(media => 
                media.type.startsWith('audio/') || 
                media.name.toLowerCase().endsWith('.mp3') ||
                media.name.toLowerCase().endsWith('.mpeg')
            );
            if(audioResponse.data.blobs2){
                const audioFiles2 = audioResponse.data.blobs2.filter(media => 
                    media.type.startsWith('audio/') || 
                    media.name.toLowerCase().endsWith('.mp3') || 
                    media.name.toLowerCase().endsWith('.mpeg')
                );
                audioFiles = [...audioFiles, ...audioFiles2];
            }

            const videoFiles = videoResponse.data.blobs.filter(media => 
                media.type.startsWith('video/') || media.name.endsWith('.mp4')
            );

            setMediaList({
                audio: audioFiles,
                video: videoFiles
            });
        } catch (error) {
            setError('Failed to fetch media list');
            console.error('Error fetching media:', error);
        }
    };

    const handleFileChange = (e) => {
        setFiles(Array.from(e.target.files));
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setFiles(Array.from(e.dataTransfer.files));
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleUpload = async () => {
        if (files.length === 0) {
            setError('Please select files to upload');
            return;
        }

        setUploading(true);
        setError('');
        setMessage('');

        const formData = new FormData();
        files.forEach(file => {
            formData.append('media', file);
        });

        try {
            await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/media/upload`,
                formData,
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            setMessage('Files uploaded successfully');
            setFiles([]);
            await fetchMediaList();
        } catch (error) {
            setError(error.response?.data?.message || 'Failed to upload files');
            console.error('Upload error:', error);
        } finally {
            setUploading(false);
        }
    };

    const toggleSection = (section) => {
        if (section === 'audio') {
            setAudioOpen(!audioOpen);
        } else if (section === 'video') {
            setVideoOpen(!videoOpen);
        }
    };

    return (
        <div className="page-container">
            <div className="page-header-stack">
                <Link to="/dashboard" className="back-button">
                    <i className="fas fa-arrow-left"></i> Back to Dashboard
                </Link>
                <h1>Media Library</h1>
            </div>

            {error && <div className="error-message">{error}</div>}
            {message && <div className="success-message">{message}</div>}

            <div className="upload-section">
                <div 
                    className="upload-box"
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                >
                    <i className="fas fa-cloud-upload-alt"></i>
                    <p>Drag and drop files here or click to select</p>
                    <input 
                        type="file" 
                        multiple 
                        onChange={handleFileChange}
                        className="file-input"
                        accept="image/*,video/mp4,audio/mpeg"
                    />
                    {files.length > 0 && (
                        <div className="selected-files">
                            <h3>Selected Files:</h3>
                            <ul>
                                {files.map((file, index) => (
                                    <li key={index}>{file.name}</li>
                                ))}
                            </ul>
                            <button 
                                onClick={handleUpload}
                                disabled={uploading}
                                className="upload-button"
                            >
                                {uploading ? 'Uploading...' : 'Upload Files'}
                            </button>
                        </div>
                    )}
                </div>
            </div>

            <div className="media-gallery">
                <div className="media-section">
                    <div 
                        className="section-header" 
                        onClick={() => toggleSection('audio')}
                    >
                        <h2>Audio Library ({mediaList.audio.length})</h2>
                        <i className={`fas fa-chevron-${audioOpen ? 'up' : 'down'}`}></i>
                    </div>
                    <div className={`section-content ${audioOpen ? 'open' : ''}`}>
                        {mediaList.audio.length === 0 ? (
                            <p>No audio files uploaded yet</p>
                        ) : (
                            <div className="media-grid">
                                {mediaList.audio.map((media) => (
                                    <div key={media.id} className="media-item audio-item">
                                        <div className="audio-container">
                                            <i className="fas fa-music"></i>
                                            <audio controls>
                                                <source 
                                                    src={media.url} 
                                                    type="audio/mpeg"
                                                    onError={(e) => console.error(`Error loading audio ${media.name}: ${media.url}`, e)}
                                                />
                                                Your browser does not support the audio element.
                                            </audio>
                                            <div className="audio-info">
                                                <p className="media-name">{media.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                <div className="media-section">
                    <div 
                        className="section-header" 
                        onClick={() => toggleSection('video')}
                    >
                        <h2>Video Library ({mediaList.video.length})</h2>
                        <i className={`fas fa-chevron-${videoOpen ? 'up' : 'down'}`}></i>
                    </div>
                    <div className={`section-content ${videoOpen ? 'open' : ''}`}>
                        {mediaList.video.length === 0 ? (
                            <p>No video files uploaded yet</p>
                        ) : (
                            <div className="media-grid">
                                {mediaList.video.map((media) => (
                                    <div key={media.id} className="media-item video-item">
                                        <video controls>
                                            <source src={media.url} type={media.type} />
                                        </video>
                                        <p className="media-name">{media.name}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
} 