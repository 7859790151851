import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import DeleteModal from '../../components/DeleteModal';

export default function AlbumList() {
    const [albums, setAlbums] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [deleteConfirm, setDeleteConfirm] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchAlbums();
    }, []);

    const fetchAlbums = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/albums`,
                { withCredentials: true }
            );
            setAlbums(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching albums:', error);
            setError('Failed to load albums');
            setLoading(false);
        }
    };

    const handleDelete = async (albumId) => {
        try {
            await axios.delete(
                `${process.env.REACT_APP_BACKEND_URL}/api/albums/${albumId}`,
                { withCredentials: true }
            );
            setAlbums(albums.filter(album => album.idalbums !== albumId));
            setDeleteConfirm(null);
        } catch (error) {
            console.error('Error deleting album:', error);
            setError('Failed to delete album');
        }
    };

    const handleAlbumClick = (albumId) => {
        navigate(`/albums/${albumId}`);
    };

    const getActivityCount = (activitiesString) => {
        if (!activitiesString) return 0;
        try {
            // Add square brackets to make it a valid JSON array
            const jsonString = `[${activitiesString}]`;
            const activities = JSON.parse(jsonString);
            return activities.length;
        } catch (error) {
            console.error('Error parsing activities:', error);
            return 0;
        }
    };

    if (loading) return <div className="loading">Loading...</div>;

    return (
        <div className="page-container">
            <div className="page-header-stack">
                <Link to="/dashboard" className="back-button">
                    <i className="fas fa-arrow-left"></i> Back to Dashboard
                </Link>
                <h1>All Albums</h1>
            </div>

            {error && <div className="error-message">{error}</div>}

            <div className="list-container">
                {albums.length > 0 ? (
                    albums.map(album => (
                        <div 
                            key={album.idalbums} 
                            className="list-item clickable"
                            onClick={() => handleAlbumClick(album.idalbums)}
                        >
                            <div className="user-info">
                                <h3>{album.name}</h3>
                                {album.shortDescription && (
                                    <p className="short-description">{album.shortDescription}</p>
                                )}
                                {album.description && (
                                    <p className="description">{album.description}</p>
                                )}
                                <p className="user-role">
                                    {getActivityCount(album.activities)} activities
                                    {!album.isPublic && (
                                        <span className="private-badge">
                                            <i className="fas fa-clock"></i> Draft
                                        </span>
                                    )}
                                </p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="no-items-message">No albums found</p>
                )}
            </div>

            {deleteConfirm && (
                <DeleteModal 
                    isOpen={!!deleteConfirm}
                    onClose={() => setDeleteConfirm(null)}
                    onConfirm={() => handleDelete(deleteConfirm.idalbums)}
                    itemName={`album "${deleteConfirm.name}"`}
                />
            )}

            <style jsx>{`
                .short-description {
                    color: #666;
                    font-weight: 500;
                    margin: 4px 0;
                    font-style: italic;
                }

                .description {
                    color: #777;
                    font-size: 0.9em;
                    margin-top: 4px;
                }
            `}</style>
        </div>
    );
} 