import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/components/forms.css';

export default function IndividualProgram() {
    const { id } = useParams();
    const [program, setProgram] = useState(null);
    const [activities, setActivities] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [showImageModal, setShowImageModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        fetchProgram();
    }, [id]);

    const fetchProgram = async () => {
        try {
            const [programResponse, activitiesResponse] = await Promise.all([
                axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/programs/${id}`,
                    { withCredentials: true }
                ),
                axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/programs/${id}/activities`,
                    { withCredentials: true }
                )
            ]);
            
            setProgram(programResponse.data);
            setActivities(activitiesResponse.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching program:', error);
            setError('Failed to load program');
            setLoading(false);
        }
    };

    const ImageModal = ({ imageUrl, onClose }) => {
        if (!imageUrl) return null;

        return (
            <div className="image-modal-overlay" onClick={onClose}>
                <div className="image-modal-content" onClick={(e) => e.stopPropagation()}>
                    <img src={imageUrl} alt="Program" />
                    <button className="close-button" onClick={onClose}>
                        <i className="fas fa-times"></i>
                    </button>
                </div>
            </div>
        );
    };

    if (loading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error-message">{error}</div>;
    if (!program) return <div className="error-message">Program not found</div>;

    return (
        <div className="page-container">
            <div className="page-header-stack">
                <Link to="/programs" className="back-button">
                    <i className="fas fa-arrow-left"></i> Back to Programs
                </Link>
                <h1>Program Details</h1>
            </div>

            <div className="detail-card">
                <div className="detail-header">
                    <h2>{program.name}</h2>
                    {program.businessName && (
                        <span className="business-badge">{program.businessName}</span>
                    )}
                </div>
                
                {program.src && (
                    <div className="program-image-container">
                        <img
                            src={program.src}
                            alt={program.name}
                            className="program-thumbnail"
                            onClick={() => {
                                setSelectedImage(program.src);
                                setShowImageModal(true);
                            }}
                        />
                    </div>
                )}

                <div className="detail-content">
                    <div className="detail-row">
                        <label>Description:</label>
                        <span>{program.description}</span>
                    </div>
                    
                    <div className="program-schedule">
                        <h3>Program Schedule</h3>
                        {activities.map((week) => (
                            <div key={week.id} className="week-section">
                                <h4 className="week-title">{week.name}</h4>
                                <div className="days-grid">
                                    {week.days.map((day) => (
                                        <div key={day.id} className="day-card">
                                            <h5 className="day-title">{day.name}</h5>
                                            <div className="activities-list">
                                                {day.activities.length > 0 ? (
                                                    day.activities.map((activity) => (
                                                        <div key={activity.id} className="activity-card">
                                                            <h6 className="activity-name">{activity.name}</h6>
                                                            {activity.short_description && (
                                                                <p className="activity-short-desc">{activity.short_description}</p>
                                                            )}
                                                            <div className="activity-details">
                                                                {activity.level_name && (
                                                                    <span className="activity-level">
                                                                        <i className="fas fa-chart-line"></i>
                                                                        Level: {activity.level_name}
                                                                    </span>
                                                                )}
                                                                {activity.step_name && (
                                                                    <span className="activity-step">
                                                                        <i className="fas fa-shoe-prints"></i>
                                                                        Step: {activity.step_name}
                                                                    </span>
                                                                )}
                                                                {activity.environment_name && (
                                                                    <span className="activity-env">
                                                                        <i className="fas fa-map-marker-alt"></i>
                                                                        Environment: {activity.environment_name}
                                                                    </span>
                                                                )}
                                                                {activity.minutes && (
                                                                    <span className="activity-duration">
                                                                        <i className="fas fa-clock"></i>
                                                                        {activity.minutes} minutes
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <p className="no-activities">No activities scheduled</p>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {showImageModal && (
                <ImageModal
                    imageUrl={selectedImage}
                    onClose={() => {
                        setShowImageModal(false);
                        setSelectedImage(null);
                    }}
                />
            )}

            <style jsx>{`
                .image-modal-overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(0, 0, 0, 0.7);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 1000;
                }

                .image-modal-content {
                    position: relative;
                    max-width: 90vw;
                    max-height: 90vh;
                    background: white;
                    padding: 10px;
                    border-radius: 8px;
                    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
                }

                .image-modal-content img {
                    max-width: 100%;
                    max-height: 90vh;
                    object-fit: contain;
                    display: block;
                }

                .close-button {
                    position: absolute;
                    top: -15px;
                    right: -15px;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: #dc3545;
                    color: white;
                    border: none;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
                }

                .close-button:hover {
                    background: #c82333;
                }

                .program-thumbnail {
                    cursor: pointer;
                    transition: transform 0.2s;
                    width: 100%;
                    height: 300px;
                    object-fit: cover;
                    border-radius: 8px;
                }

                .program-thumbnail:hover {
                    transform: scale(1.02);
                }

                .program-image-container {
                    margin: 15px 0;
                    width: 100%;
                }

                .detail-card {
                    background: white;
                    border-radius: 8px;
                    padding: 20px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                }

                .detail-header {
                    margin-bottom: 20px;
                    padding-bottom: 15px;
                    border-bottom: 1px solid #eee;
                }

                .activity-card {
                    background: #f8f9fa;
                    border-radius: 8px;
                    padding: 15px;
                    margin-bottom: 15px;
                }

                .activity-name {
                    font-size: 1.1em;
                    margin: 0 0 10px 0;
                    color: #2c3e50;
                }

                .activity-short-desc {
                    color: #666;
                    margin-bottom: 12px;
                    font-size: 0.9em;
                }

                .activity-details {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 12px;
                    font-size: 0.9em;
                }

                .activity-details span {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    padding: 4px 8px;
                    border-radius: 4px;
                    background: white;
                    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
                }

                .activity-details i {
                    color: #666;
                }

                .activity-level i {
                    color: #2ecc71;
                }

                .activity-step i {
                    color: #3498db;
                }

                .activity-env i {
                    color: #e74c3c;
                }

                .activity-duration i {
                    color: #f39c12;
                }

                .days-grid {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
                    gap: 20px;
                    margin-top: 15px;
                }

                .day-card {
                    background: white;
                    border-radius: 8px;
                    padding: 15px;
                    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
                }

                .day-title {
                    margin: 0 0 15px 0;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #eee;
                    color: #2c3e50;
                }

                .week-title {
                    color: #2c3e50;
                    margin: 25px 0 15px 0;
                    padding-bottom: 10px;
                    border-bottom: 2px solid #3498db;
                }
            `}</style>
        </div>
    );
}