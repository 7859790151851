import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/user/CreateUser.css';
import axios from 'axios';

const CreateUser = () => {
  const [userData, setUserData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    name: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userData.password !== userData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/register`, userData, {
        withCredentials: true
      });
      const data = response.data;
      if (data.success) {
        setSuccess(data.message);
        navigate('/login');
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError('Registration failed');
    }
  };

  return (
    <div className="create-user-container">
      <form onSubmit={handleSubmit} className="create-user-form">
        <h2>Create Account</h2>
        {error && <div className="error-message">{error}</div>}
        <input
          type="text"
          placeholder="Name"
          value={userData.name}
          onChange={(e) => setUserData({...userData, name: e.target.value})}
        />
        <input
          type="email"
          placeholder="Email"
          value={userData.email}
          onChange={(e) => setUserData({...userData, email: e.target.value})}
        />
        <input
          type="password"
          placeholder="Password"
          value={userData.password}
          onChange={(e) => setUserData({...userData, password: e.target.value})}
        />
        <input
          type="password"
          placeholder="Confirm Password"
          value={userData.confirmPassword}
          onChange={(e) => setUserData({...userData, confirmPassword: e.target.value})}
        />
        <button type="submit">Create Account</button>
      </form>
    </div>
  );
};

export default CreateUser; 