import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import DeleteModal from "../../components/DeleteModal";
import "../../styles/components/forms.css";
import ActivitySelector from "../../components/common/ActivitySelector";

export default function NewProgram() {
  const [programName, setProgramName] = useState("");
  const [programDescription, setProgramDescription] = useState("");
  const [programImage, setProgramImage] = useState(null);
  const [editDescription, setEditDescription] = useState("");
  const [editImage, setEditImage] = useState(null);
  const [programs, setPrograms] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [editName, setEditName] = useState("");
  const [deleteConfirm, setDeleteConfirm] = useState(null);
  const [weeks, setWeeks] = useState([]);
  const [activities, setActivities] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedWeeks, setExpandedWeeks] = useState([]);
  const [levels, setLevels] = useState([]);
  const [programLevelId, setProgramLevelId] = useState("");
  const [editLevelId, setEditLevelId] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [msg, setMsg] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    fetchInitialData();
    fetchActivities();
  }, []);

  const fetchInitialData = async () => {
    try {
      const [programsResponse, levelsResponse] = await Promise.all([
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/programs`, {
          withCredentials: true,
        }),
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/levels`, {
          withCredentials: true,
        }),
      ]);
      setPrograms(programsResponse.data);
      setLevels(levelsResponse.data);
    } catch (error) {
      console.error("Error fetching initial data:", error);
      setError("Error fetching initial data");
    }
  };

  const fetchPrograms = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/programs`,
        {
          withCredentials: true,
        }
      );
      setPrograms(response.data);
    } catch (error) {
      console.error("Error fetching programs:", error);
      setError("Error fetching programs");
    }
  };

  const fetchActivities = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/activities`,
        {
          withCredentials: true,
        }
      );
      setActivities(response.data);
    } catch (error) {
      console.error("Error fetching activities:", error);
      setError("Failed to fetch activities: " + error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!programName.trim()) return;

    try {
      const formData = new FormData();
      formData.append("name", programName);
      formData.append("description", programDescription);
      formData.append("levelId", programLevelId);

      // Process weeks data
      const processedWeeks = weeks.map((week) => ({
        name: `Week ${week.weekNumber}`,
        description: week.description,
        days: week.days.map((day) => ({
          name: `Day ${day.dayNumber}`,
          description: day.description,
          activities: Array.isArray(day.activities) ? day.activities : [],
        })),
      }));

      // Extract week names and descriptions
      const weekNames = processedWeeks.map((week) => week.name);
      const weekDescriptions = processedWeeks.reduce((acc, week) => {
        acc[week.name] = week.description || "";
        return acc;
      }, {});

      // Extract all unique days with descriptions
      const allDays = [];
      const dayDescriptions = {};
      processedWeeks.forEach((week) => {
        week.days.forEach((day) => {
          if (!allDays.includes(day.name)) {
            allDays.push(day.name);
            dayDescriptions[day.name] = day.description || "";
          }
        });
      });

      // Add structured data to formData
      formData.append("weeks", JSON.stringify(weekNames));
      formData.append("days", JSON.stringify(allDays));
      formData.append("weekDescriptions", JSON.stringify(weekDescriptions));
      formData.append("dayDescriptions", JSON.stringify(dayDescriptions));

      // Add activities data
      const activitiesData = {};
      processedWeeks.forEach((week) => {
        week.days.forEach((day) => {
          if (day.activities && day.activities.length > 0) {
            activitiesData[day.name] = day.activities.map(
              (activity) => activity.idactivity
            );
          }
        });
      });
      formData.append("activitiesData", JSON.stringify(activitiesData));

      // Add image if exists
      if (programImage) {
        formData.append("image", programImage);
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/programs`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        // Reset form
        setProgramName("");
        setProgramDescription("");
        setProgramImage(null);
        setProgramLevelId("");
        setWeeks([]);

        // Refresh programs list
        await fetchPrograms();

        // Show success message
        setMsg("Program created successfully!");
      }
    } catch (error) {
      console.error("Error creating program:", error);
      setError(error.response?.data?.message || "Failed to create program");
    }
  };

  const handleEdit = async (id) => {
    if (editingId === id) {
      try {
        const formData = new FormData();
        formData.append("name", editName);
        formData.append("description", editDescription);
        formData.append("levelId", editLevelId);

        // Process weeks data
        const processedWeeks = weeks.map((week) => ({
          name: `Week ${week.weekNumber}`,
          days: week.days.map((day) => ({
            name: `Day ${day.dayNumber}`,
            activities: day.activities,
          })),
        }));

        // Extract all unique days
        const allDays = [];
        processedWeeks.forEach((week) => {
          week.days.forEach((day) => {
            if (!allDays.includes(day.name)) {
              allDays.push(day.name);
            }
          });
        });

        // Add structured data to formData
        formData.append(
          "weeks",
          JSON.stringify(processedWeeks.map((week) => week.name))
        );
        formData.append("days", JSON.stringify(allDays));

        // Add activities data
        const activitiesData = {};
        processedWeeks.forEach((week) => {
          week.days.forEach((day) => {
            if (day.activities && day.activities.length > 0) {
              activitiesData[day.name] = day.activities.map(
                (activity) => activity.idactivity
              );
            }
          });
        });
        formData.append("activitiesData", JSON.stringify(activitiesData));

        if (editImage) {
          formData.append("image", editImage);
        }

        const response = await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/api/programs/${id}`,
          formData,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          setEditingId(null);
          setEditName("");
          setEditDescription("");
          setEditImage(null);
          setEditLevelId("");
          setWeeks([]);
          await fetchPrograms();
        }
      } catch (error) {
        console.error("Error updating program:", error);
        setError(error.response?.data?.message || "Failed to update program");
      }
    } else {
      const program = programs.find((p) => p.id === id);
      setEditingId(id);
      setEditName(program.name);
      setEditDescription(program.description || "");
      setEditLevelId(program.intensity_zone_idintensity_zone || "");

      // Convert program weeks and days to the format expected by the form
      const formattedWeeks = [];
      Object.entries(program.weeks).forEach(([weekId, weekData], weekIndex) => {
        const week = {
          weekNumber: weekIndex + 1,
          days: [],
        };

        Object.entries(weekData.days).forEach(([dayId, dayData], dayIndex) => {
          week.days.push({
            dayNumber: dayIndex + 1,
            activities: dayData.activities.map((activity) => ({
              idactivity: activity.id,
              name: activity.name,
              instanceId: Date.now() + dayIndex,
            })),
          });
        });

        formattedWeeks.push(week);
      });

      setWeeks(formattedWeeks);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/programs/${id}`,
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setDeleteConfirm(null);
        await fetchPrograms();
      }
    } catch (error) {
      console.error("Error deleting program:", error);
    }
  };

  const handleEditKeyPress = async (e, id) => {
    if (e.key === "Enter") {
      e.preventDefault();
      await handleEdit(id);
    } else if (e.key === "Escape") {
      setEditingId(null);
      setEditName("");
    }
  };

  const addWeek = () => {
    setWeeks((prevWeeks) => [
      ...prevWeeks,
      {
        weekNumber: prevWeeks.length + 1,
        days: [],
      },
    ]);
  };

  const removeWeek = (weekIndex) => {
    const updatedWeeks = weeks.filter((_, index) => index !== weekIndex);
    // Update week numbers
    updatedWeeks.forEach((week, index) => {
      week.weekNumber = index + 1;
      week.days.forEach((day) => {
        day.weekNumber = index + 1;
      });
    });
    setWeeks(updatedWeeks);
  };

  const removeDay = (weekIndex, dayIndex) => {
    const updatedWeeks = [...weeks];
    updatedWeeks[weekIndex].days = updatedWeeks[weekIndex].days.filter(
      (_, index) => index !== dayIndex
    );
    // Update day numbers
    updatedWeeks[weekIndex].days.forEach((day, index) => {
      day.dayNumber = index + 1;
    });
    setWeeks(updatedWeeks);
  };

  const addDay = (weekIndex) => {
    const updatedWeeks = [...weeks];
    const currentWeek = updatedWeeks[weekIndex];
    currentWeek.days.push({
      dayNumber: currentWeek.days.length + 1,
      weekNumber: currentWeek.weekNumber,
      description: "",
      activities: [],
      searchTerm: "",
    });
    setWeeks(updatedWeeks);
  };

  const handleWeekDescriptionChange = (weekIndex, description) => {
    const updatedWeeks = [...weeks];
    updatedWeeks[weekIndex].description = description;
    setWeeks(updatedWeeks);
  };

  const handleDayDescriptionChange = (weekIndex, dayIndex, description) => {
    const updatedWeeks = [...weeks];
    updatedWeeks[weekIndex].days[dayIndex].description = description;
    setWeeks(updatedWeeks);
  };

  const addActivityToDay = (weekIndex, dayIndex, activity) => {
    const updatedWeeks = [...weeks];
    const currentDay = updatedWeeks[weekIndex].days[dayIndex];

    if (!currentDay.activities) {
      currentDay.activities = [];
    }

    currentDay.activities = [
      ...currentDay.activities,
      {
        idactivity: activity.idactivity,
        name: activity.name,
        instanceId: Date.now(),
      },
    ];
    currentDay.searchTerm = "";
    setWeeks(updatedWeeks);
  };

  const removeActivityFromDay = (weekIndex, dayIndex, instanceId) => {
    const updatedWeeks = [...weeks];
    const currentDay = updatedWeeks[weekIndex].days[dayIndex];

    if (currentDay.activities) {
      currentDay.activities = currentDay.activities.filter(
        (a) => a.instanceId !== instanceId
      );
      setWeeks(updatedWeeks);
    }
  };

  const handleSearchKeyDown = (e, weekIndex, dayIndex) => {
    if (e.key === "Escape") {
      const updatedWeeks = [...weeks];
      updatedWeeks[weekIndex].days[dayIndex].searchTerm = "";
      setWeeks(updatedWeeks);
    }
  };

  const toggleWeek = (weekIndex) => {
    setExpandedWeeks((prev) => {
      if (prev.includes(weekIndex)) {
        return prev.filter((index) => index !== weekIndex);
      } else {
        return [...prev, weekIndex];
      }
    });
  };

  // First, let's organize the data when we receive it
  const organizeProgram = (program) => {
    const organized = {
      idprograms: program.id,
      name: program.name,
      description: program.description,
      image_url: program.image_url,
      weeks: {},
    };

    // Group by week, then by day, then activities
    if (program.activities) {
      program.activities.forEach((activity) => {
        const weekName = activity.week_name;
        const dayName = activity.day_name;

        if (!organized.weeks[weekName]) {
          organized.weeks[weekName] = {};
        }
        if (!organized.weeks[weekName][dayName]) {
          organized.weeks[weekName][dayName] = [];
        }
        organized.weeks[weekName][dayName].push({
          name: activity.name,
          description: activity.description,
        });
      });
    }

    return organized;
  };

  const ImageModal = ({ imageUrl, onClose }) => {
    if (!imageUrl) return null;

    return (
      <div className="image-modal-overlay" onClick={onClose}>
        <div
          className="image-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <img src={imageUrl} alt="Program" />
          <button className="close-button" onClick={onClose}>
            <i className="fas fa-times"></i>
          </button>
        </div>
      </div>
    );
  };

  const ProgramList = ({ programs }) => {
    return (
      <div className="programs-list">
        <h2>Existing Programs</h2>
        {msg && <div className="success-message">{msg}</div>}
            {error && <div className="error-message">{error}</div>}

        <div className="list-container">
          {programs.length > 0 ? (
            programs.map((program) => (
              <div key={program.id} className="list-item">
                {editingId === program.id ? (
                  // Edit mode
                  <div className="edit-form">
                    <input
                      type="text"
                      value={editName}
                      onChange={(e) => setEditName(e.target.value)}
                      className="edit-input"
                      placeholder="Program name"
                    />
                    <textarea
                      value={editDescription}
                      onChange={(e) => setEditDescription(e.target.value)}
                      className="edit-textarea"
                      placeholder="Program description"
                    />
                    <input
                      type="file"
                      onChange={(e) => setEditImage(e.target.files[0])}
                      className="edit-file-input"
                      accept="image/*"
                    />
                    <div className="edit-actions">
                      <button
                        onClick={() => handleEdit(program.id)}
                        className="save-button"
                      >
                        Save
                      </button>
                      <button
                        onClick={() => setEditingId(null)}
                        className="cancel-button"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="program-header">
                      <h3>{program.name}</h3>
                      <div className="program-actions">
                        <button
                          className="edit-button"
                          onClick={() => handleEdit(program.id)}
                        >
                          <i className="fas fa-pencil-alt"></i>
                        </button>
                        <button
                          className="delete-button"
                          onClick={() => {
                            setDeleteConfirm(program);
                          }}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </button>
                      </div>
                    </div>

                    {program.description && (
                      <p className="program-description">
                        {program.description}
                      </p>
                    )}

                    {program.image_url && (
                      <div className="program-image-container">
                        <img
                          src={program.image_url}
                          alt={program.name}
                          className="program-thumbnail"
                          onClick={() => {
                            setSelectedImage(program.image_url);
                            setShowImageModal(true);
                          }}
                        />
                      </div>
                    )}

                    <div className="program-content">
                      {Object.entries(program.weeks || {}).map(([weekName, weekData]) => (
                        <div key={weekName} className="week-section">
                          <h4 className="week-title">{weekData.name}</h4>
                          {weekData.description && (
                            <p className="week-description-text">
                              {weekData.description}
                            </p>
                          )}
                          <div className="days-grid">
                            {Object.entries(weekData.days || {}).map(([dayNumber, dayData]) => (
                              <div key={dayNumber} className="day-card">
                                <h5 className="day-title">{dayData.name}</h5>
                                {dayData.description && (
                                  <p className="day-description-text">
                                    {dayData.description}
                                  </p>
                                )}
                                <div className="activities-list programs-list">
                                  {dayData.activities.length > 0 ? (
                                    dayData.activities.map((activity, index) => (
                                      <div key={index} className="activity-card">
                                        <span className="activity-name" id={activity.idactivity}>
                                          {activity.name}
                                        </span>{" "}
                                        <span className="activity-description">
                                          {activity.description}
                                        </span>
                                      </div>
                                    ))
                                  ) : (
                                    <p className="no-activities">No activities scheduled</p>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            ))
          ) : (
            <div className="no-programs">No programs available</div>
          )}
        </div>
        {showImageModal && (
          <ImageModal
            imageUrl={selectedImage}
            onClose={() => {
              setShowImageModal(false);
              setSelectedImage(null);
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div className="new-program-container">
      <div className="page-container">
        <div className="page-header-stack">
          <Link to="/dashboard" className="back-button">
            <i className="fas fa-arrow-left"></i> Back to Dashboard
          </Link>
          <h1>Create New Program</h1>
        </div>
        <form className="form-container" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="programName">Program Name</label>
            <input
              type="text"
              id="programName"
              name="programName"
              value={programName}
              onChange={(e) => setProgramName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="programDescription">Description</label>
            <textarea
              id="programDescription"
              name="programDescription"
              value={programDescription}
              onChange={(e) => setProgramDescription(e.target.value)}
              rows="4"
            />
          </div>
          <div className="form-group">
            <label htmlFor="programImage">Program Image</label>
            <input
              type="file"
              id="programImage"
              name="programImage"
              accept="image/*"
              onChange={(e) => setProgramImage(e.target.files[0])}
            />
          </div>
          <div className="form-group">
            <label htmlFor="levelId">Intensity Level</label>
            <select
              id="levelId"
              name="levelId"
              value={programLevelId}
              onChange={(e) => setProgramLevelId(e.target.value)}
              className="form-select"
            >
              <option value="">Select a level</option>
              {levels.map((level) => (
                <option
                  key={level.idintensity_zone}
                  value={level.idintensity_zone}
                  style={{
                    backgroundColor: level.fargekode_bakgrunn,
                    color: level.fargekode_tekst,
                  }}
                >
                  {level.name}
                </option>
              ))}
            </select>
          </div>

          <div className="weeks-section" style={{ marginTop: "20px" }}>
            <h2>Program Weeks</h2>
            <button
              type="button"
              onClick={addWeek}
              style={{
                backgroundColor: "#28a745",
                color: "white",
                padding: "10px 20px",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                marginBottom: "20px",
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <i className="fas fa-plus"></i> Add Week
            </button>

            {weeks.map((week, weekIndex) => (
              <div
                key={weekIndex}
                className="week-container"
                style={{
                  border: "1px solid #ddd",
                  padding: "20px",
                  marginBottom: "20px",
                  borderRadius: "4px",
                  backgroundColor: "#f8f9fa",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: expandedWeeks.includes(weekIndex)
                      ? "15px"
                      : "0",
                    cursor: "pointer",
                  }}
                  onClick={() => toggleWeek(weekIndex)}
                >
                  <h3 style={{ margin: 0 }}>Week {week.weekNumber}</h3>
                  <div>
                    <button
                      type="button"
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        padding: "8px",
                        color: "#666",
                      }}
                    >
                      <i
                        className={`fas fa-chevron-${
                          expandedWeeks.includes(weekIndex) ? "up" : "down"
                        }`}
                      ></i>
                    </button>
                    <button
                      type="button"
                      onClick={() => removeWeek(weekIndex)}
                      className="delete-button"
                      title="Delete week"
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  </div>
                </div>

                {expandedWeeks.includes(weekIndex) && (
                  <>
                    <div
                      className="week-description"
                      style={{ marginBottom: "15px" }}
                    >
                      <input
                        type="text"
                        value={week.description || ""}
                        onChange={(e) =>
                          handleWeekDescriptionChange(weekIndex, e.target.value)
                        }
                        placeholder="Enter week description..."
                        onClick={(e) => e.stopPropagation()}
                        style={{
                          width: "100%",
                          padding: "8px",
                          borderRadius: "4px",
                          border: "1px solid #ddd",
                          marginTop: "10px",
                        }}
                      />
                    </div>
                    <button
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        addDay(weekIndex);
                      }}
                      style={{
                        backgroundColor: "#28a745",
                        color: "white",
                        padding: "8px 16px",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                        marginBottom: "20px",
                        marginTop: "15px",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        fontSize: "0.9em",
                      }}
                    >
                      <i className="fas fa-plus"></i> Add Day
                    </button>

                    {week.days.map((day, dayIndex) => (
                      <div
                        key={dayIndex}
                        className="day-container"
                        style={{
                          border: "1px solid #ddd",
                          padding: "15px",
                          marginBottom: "15px",
                          borderRadius: "4px",
                          backgroundColor: "white",
                        }}
                      >
                        <div className="day-header">
                          <h4 style={{ marginBottom: "15px" }}>
                            Day {day.dayNumber}
                          </h4>
                                     <button
                                                type="button"
                                                onClick={() => removeDay(weekIndex, dayIndex)}
                                                className="delete-button"
                                                title="Delete day"
                                            >
                                                <i className="fas fa-trash"></i>
                                            </button>
                        </div>

                        <div
                          className="day-description"
                          style={{ marginBottom: "15px" }}
                        >
                          <input
                            type="text"
                            value={day.description || ""}
                            onChange={(e) =>
                              handleDayDescriptionChange(
                                weekIndex,
                                dayIndex,
                                e.target.value
                              )
                            }
                            placeholder="Enter day description..."
                            style={{
                              width: "100%",
                              padding: "8px",
                              borderRadius: "4px",
                              border: "1px solid #ddd",
                              marginBottom: "10px",
                            }}
                          />
                        </div>

                        <ActivitySelector
                          dayNumber={day.dayNumber}
                          searchTerm={day.searchTerm}
                          activities={activities}
                          selectedActivities={day.activities}
                          onSearchChange={(e) => {
                            const updatedWeeks = [...weeks];
                            updatedWeeks[weekIndex].days[dayIndex].searchTerm =
                              e.target.value;
                            setWeeks(updatedWeeks);
                          }}
                          onSearchKeyDown={(e) =>
                            handleSearchKeyDown(e, weekIndex, dayIndex)
                          }
                          onActivityAdd={(activity) =>
                            addActivityToDay(weekIndex, dayIndex, activity)
                          }
                          onActivityRemove={(instanceId) =>
                            removeActivityFromDay(
                              weekIndex,
                              dayIndex,
                              instanceId
                            )
                          }
                        />
                      </div>
                    ))}
                  </>
                )}
              </div>
            ))}
          </div>

          <button type="submit" className="submit-button">
            Create Program
          </button>
        </form>

        <ProgramList
          programs={programs}
          onEdit={handleEdit}
          onDelete={setDeleteConfirm}
        />

        {deleteConfirm && (
          <DeleteModal
            isOpen={!!deleteConfirm}
            onClose={() => setDeleteConfirm(null)}
            onConfirm={() => handleDelete(deleteConfirm.id)}
            itemName={`program "${
                deleteConfirm.name
              }"`}
          />
        )}
      </div>
    </div>
  );
}
