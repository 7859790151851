import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import DeleteModal from '../../components/DeleteModal';
import '../../styles/components/forms.css';

export default function NewEnvironment() {
    const [formData, setFormData] = useState({
        name: '',
        description: ''
    });
    const [environments, setEnvironments] = useState([]);
    const [editingId, setEditingId] = useState(null);
    const [editData, setEditData] = useState({
        name: '',
        description: ''
    });
    const [deleteConfirm, setDeleteConfirm] = useState(null);
    const [msg, setMsg] = useState('');

    useEffect(() => {
        fetchEnvironments();
    }, []);

    const fetchEnvironments = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/environments`,
                { withCredentials: true }
            );
            setEnvironments(response.data);
        } catch (error) {
            console.error('Error fetching environments:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/environments`,
                formData,
                { withCredentials: true }
            );

            if (response.status === 201) {
                setFormData({
                    name: '',
                    description: ''
                });
                setMsg('Environment created successfully');
                setTimeout(() => setMsg(''), 4000);
                await fetchEnvironments();
            }
        } catch (error) {
            console.error('Error creating environment:', error);
        }
    };

    const handleEdit = async (id) => {
        if (editingId === id) {
            try {
                const response = await axios.put(
                    `${process.env.REACT_APP_BACKEND_URL}/api/environments/${id}`,
                    editData,
                    { withCredentials: true }
                );

                if (response.status === 200) {
                    setEditingId(null);
                    setEditData({
                        name: '',
                        description: ''
                    });
                    await fetchEnvironments();
                }
            } catch (error) {
                console.error('Error updating environment:', error);
            }
        } else {
            const environment = environments.find(e => e.idenvironment === id);
            setEditData({
                name: environment.name,
                description: environment.description || ''
            });
            setEditingId(id);
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BACKEND_URL}/api/environments/${id}`,
                { withCredentials: true }
            );
            
            if (response.status === 200) {
                setDeleteConfirm(null);
                await fetchEnvironments();
            }
        } catch (error) {
            console.error('Error deleting environment:', error);
        }
    };

    return (
        <div className="page-container">
            <div className="page-header-stack">
                <Link to="/dashboard" className="back-button">
                    <i className="fas fa-arrow-left"></i> Back to Dashboard
                </Link>
                <h1>Create New Environment</h1>
            </div>

            <form className="form-container" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Environment Name</label>
                    <input
                        type="text"
                        id="name"
                        value={formData.name}
                        onChange={(e) => setFormData({...formData, name: e.target.value})}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea
                        id="description"
                        value={formData.description}
                        onChange={(e) => setFormData({...formData, description: e.target.value})}
                        rows="4"
                    />
                </div>

                <button type="submit" className="submit-button">Create Environment</button>
            </form>

            {msg && <div className="success-message">{msg}</div>}

            <div className="environments-list">
                <h2>Existing Environments</h2>
                <div className="list-container">
                    {environments.map((environment) => (
                        <div key={environment.idenvironment} className="list-item">
                            {editingId === environment.idenvironment ? (
                                <div className="edit-form">
                                    <input
                                        type="text"
                                        value={editData.name}
                                        onChange={(e) => setEditData({...editData, name: e.target.value})}
                                        className="edit-input"
                                    />
                                    <textarea
                                        value={editData.description}
                                        onChange={(e) => setEditData({...editData, description: e.target.value})}
                                        className="edit-textarea"
                                    />
                                </div>
                            ) : (
                                <div className="environment-info">
                                    <h3>{environment.name}</h3>
                                    {environment.description && (
                                        <p className="environment-description">{environment.description}</p>
                                    )}
                                </div>
                            )}
                            <div className="list-item-actions">
                                <button
                                    className="edit-button"
                                    onClick={() => handleEdit(environment.idenvironment)}
                                >
                                    <i className={`fas ${editingId === environment.idenvironment ? 'fa-save' : 'fa-pencil-alt'}`}></i>
                                </button>
                                <button
                                    className="delete-button"
                                    onClick={() => setDeleteConfirm(environment)}
                                >
                                    <i className="fas fa-trash-alt"></i>
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {deleteConfirm && (
                <DeleteModal
                    isOpen={!!deleteConfirm}
                    onClose={() => setDeleteConfirm(null)}
                    onConfirm={() => handleDelete(deleteConfirm.idenvironment)}
                    itemName={`environment "${deleteConfirm.name}"`}
                />
            )}
        </div>
    );
} 