import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import DeleteModal from '../../components/DeleteModal';
import MediaSelector from '../../components/MediaSelector';

export default function NewActivity() {
    const [formData, setFormData] = useState({
        activityName: '',
        programId: [],
        shortDescription: '',
        description: '',
        voicingLevel: 'some',
        levelId: '',
        stepId: '',
        environmentId: '',
        isPublic: false,
        isFree: true,
        minutes: ''
    });
    const [programs, setPrograms] = useState([]);
    const [tags, setTags] = useState([]);
    const [tagInput, setTagInput] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [existingTags, setExistingTags] = useState([]);
    const [activities, setActivities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [msg, setMsg] = useState("");
    const [editingId, setEditingId] = useState(null);
    const [editData, setEditData] = useState({
        activityName: '',
        programId: [],
        shortDescription: '',
        description: '',
        voicingLevel: 'some',
        tags: [],
        levelId: '',
        stepId: '',
        environmentId: '',
        isPublic: true,
        isFree: false,
        minutes: '',
        audioUrl: '',
        videoUrl: ''
    });
    const [editTagInput, setEditTagInput] = useState('');
    const [editTagSuggestions, setEditTagSuggestions] = useState([]);
    const [deleteConfirm, setDeleteConfirm] = useState(null);
    const [levels, setLevels] = useState([]);
    const [steps, setSteps] = useState([]);
    const [environments, setEnvironments] = useState([]);
    const [audioUrl, setAudioUrl] = useState('');
    const [videoUrl, setVideoUrl] = useState('');
    const [audioPlayers, setAudioPlayers] = useState({});
    const [playingStates, setPlayingStates] = useState({});

    const truncateText = (text, maxLength) => {
        if (!text) return '';
        if (text.length <= maxLength) return text;
        return text.slice(0, maxLength) + '...';
    };

    const fetchActivities = async () => {
        try {
            const activitiesResponse = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/activities`,
                { withCredentials: true }
            );
            setActivities(activitiesResponse.data);
        } catch (error) {
            console.error('Error fetching activities:', error);
            setError('Failed to fetch activities');
        }
    };

    const fetchInitialData = async () => {
        setLoading(true);
        try {
            const [programsResponse, tagsResponse, levelsResponse, stepsResponse, environmentsResponse] = await Promise.all([
                axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/programs`,
                    { withCredentials: true }
                ),
                axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/activities/tags`,
                    { withCredentials: true }
                ),
                axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/levels`,
                    { withCredentials: true }
                ),
                axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/steps`,
                    { withCredentials: true }
                ),
                axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/environments`,
                    { withCredentials: true }
                )
            ]);

            setPrograms(programsResponse.data);
            setExistingTags(tagsResponse.data);
            setLevels(levelsResponse.data);
            setSteps(stepsResponse.data);
            setEnvironments(environmentsResponse.data);
            await fetchActivities();
        } catch (err) {
            console.error('Error fetching initial data:', err);
            setError('Failed to load necessary data. Please try again.' + err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchInitialData();
    }, [msg]);

    const handleEdit = (activity) => {
        const programIds = activity.idprograms ? 
            (typeof activity.idprograms === 'string' ? 
                activity.idprograms.split(',').map(id => parseInt(id)) : 
                [activity.idprograms]) : 
            [];

        // Function to trim URL query parameters
        const trimUrl = (url) => {
            if (!url) return '';
            try {
                // Remove everything after '?' or '#' in the URL
                return url.split(/[?#]/)[0];
            } catch (error) {
                console.error('Error trimming URL:', error);
                return url;
            }
        };

        setEditingId(activity.idactivity);
        setEditData({
            activityName: activity.name || '',
            programId: programIds,
            shortDescription: activity.short_description || '',
            description: activity.description || '',
            voicingLevel: activity.voicing_level || 'some',
            tags: activity.tags ? activity.tags.split(',') : [],
            levelId: activity.intensity_zone_idintensity_zone || '',
            stepId: activity.steps_idsteps || '',
            environmentId: activity.environment_idenvironment || '',
            isPublic: activity.isPublic === 1,
            isFree: activity.isFree === 1,
            minutes: activity.minutes || '',
            // Trim the URLs to remove query parameters
            audioUrl: trimUrl(activity.norwegianURL) || '',
            videoUrl: trimUrl(activity.videoUrl) || ''
        });
    };

    const handleUpdate = async (id) => {
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/api/activities/${id}`,
                {
                    name: editData.activityName,
                    shortDescription: editData.shortDescription,
                    description: editData.description,
                    voicingLevel: editData.voicingLevel,
                    tags: editData.tags,
                    idintensity_zone: editData.levelId,
                    idsteps: editData.stepId,
                    idenvironment: editData.environmentId || null,
                    isPublic: editData.isPublic ? 1 : 0,
                    isFree: editData.isFree ? 1 : 0,
                    minutes: editData.minutes,
                    norwegianUrl: editData.audioUrl,
                    englishUrl: editData.audioUrl,
                    videoUrl: editData.videoUrl
                },
                { withCredentials: true }
            );

            if (response.status === 200) {
                setEditingId(null);
                setMsg("Activity updated successfully");
                setEditData({
                    activityName: '',
                    programId: [],
                    shortDescription: '',
                    description: '',
                    voicingLevel: 'some',
                    tags: [],
                    levelId: '',
                    stepId: '',
                    environmentId: '',
                    isPublic: false,
                    isFree: true,
                    minutes: '',
                    audioUrl: '',
                    videoUrl: ''
                });
                await fetchActivities();
            }
        } catch (error) {
            console.error('Error updating activity:', error);
            setError('Failed to update activity. Please try again.');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'programId') {
            const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value);
            setFormData(prev => ({
                ...prev,
                [name]: selectedOptions
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleTagInput = (e) => {
        const value = e.target.value;
        setTagInput(value);
        
        if (value.trim()) {
            const filtered = existingTags
                .filter(tag => tag.name.toLowerCase().includes(value.toLowerCase()))
                .map(tag => tag.name);
            setSuggestions(filtered);
        } else {
            setSuggestions([]);
        }
    };

    const addTag = (tagName) => {
        if (!tags.includes(tagName) && tagName.trim()) {
            setTags([...tags, tagName.trim()]);
        }
        setTagInput('');
        setSuggestions([]);
    };

    const removeTag = (tagToRemove) => {
        setTags(tags.filter(tag => tag !== tagToRemove));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/activities`,
                {
                    ...formData,
                    isPublic: formData.isPublic ? 1 : 0,
                    isFree: formData.isFree ? 1 : 0,
                    tags: tags,
                    audioUrl: audioUrl,
                    videoUrl: videoUrl,
                    voicingLevel: formData.voicingLevel,
                    norwegianUrl: audioUrl,
                    englishUrl: audioUrl,
                    environmentId: formData.environmentId || null
                },
                { withCredentials: true }
            );

            if (response.status === 201) {
                setFormData({ 
                    activityName: '', 
                    programId: [], 
                    description: '',
                    shortDescription: '',
                    voicingLevel: 'some',
                    levelId: '',
                    stepId: '',
                    environmentId: '',
                    isPublic: false,
                    isFree: true,
                    minutes: ''
                });
                setTags([]);
                setTagInput('');
                setVideoUrl('');
                setAudioUrl('');
                setMsg(response.data.message);
            }
        } catch (error) {
            console.error('Error creating activity:', error);
            setError('Failed to create activity. Please try again.');
        }
    };

    const handleEditTagInput = (e) => {
        const value = e.target.value;
        setEditTagInput(value);
        
        if (value.trim()) {
            const filtered = existingTags
                .filter(tag => tag.name.toLowerCase().includes(value.toLowerCase()))
                .map(tag => tag.name);
            setEditTagSuggestions(filtered);
        } else {
            setEditTagSuggestions([]);
        }
    };

    const addEditTag = (tagName) => {
        if (!editData.tags.includes(tagName) && tagName.trim()) {
            setEditData({
                ...editData,
                tags: [...editData.tags, tagName.trim()]
            });
        }
        setEditTagInput('');
        setEditTagSuggestions([]);
    };

    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BACKEND_URL}/api/activities/${id}`,
                { withCredentials: true }
            );
            
            if (response.status === 200) {
                setDeleteConfirm(null);
                setMsg("Activity deleted successfully");
                // Update the activities list by filtering out the deleted activity
                setActivities(activities.filter(activity => activity.idactivity !== id));
            }
        } catch (error) {
            console.error('Error deleting activity:', error);
            setError('Failed to delete activity. Please try again.');
        }
    };

    const handlePlayAudio = (activityId, url, language) => {
        const playerKey = `${activityId}-${language}`;
        
        // If already playing, pause it
        if (playingStates[playerKey]) {
            audioPlayers[playerKey].pause();
            setPlayingStates(prev => ({
                ...prev,
                [playerKey]: false
            }));
            return;
        }

        // Pause any other playing audio
        Object.entries(audioPlayers).forEach(([key, player]) => {
            if (key !== playerKey && playingStates[key]) {
                player.pause();
                setPlayingStates(prev => ({
                    ...prev,
                    [key]: false
                }));
            }
        });

        // Play the selected audio
        let audio = audioPlayers[playerKey];
        if (!audio) {
            audio = new Audio(url);
            audio.addEventListener('ended', () => {
                setPlayingStates(prev => ({
                    ...prev,
                    [playerKey]: false
                }));
            });
            setAudioPlayers(prev => ({
                ...prev,
                [playerKey]: audio
            }));
        }
        
        audio.play();
        setPlayingStates(prev => ({
            ...prev,
            [playerKey]: true
        }));
    };

    useEffect(() => {
        return () => {
            // Cleanup audio players on unmount
            Object.values(audioPlayers).forEach(player => {
                player.pause();
            });
        };
    }, [audioPlayers]);

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    if (error) {
        // return <div className="error-message">{error}</div>;
    }

    return (
        <div className="page-container">
            <div className="page-header-stack">
                <Link to="/dashboard" className="back-button">
                    <i className="fas fa-arrow-left"></i> Back to Dashboard
                </Link>
                <h1>Create New Activity</h1>
            </div>
            <form className="form-container" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="activityName">Activity Name</label>
                    <input
                        type="text"
                        id="activityName"
                        name="activityName"
                        value={formData.activityName}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="shortDescription">Short Description</label>
                    <textarea
                        id="shortDescription"
                        name="shortDescription"
                        value={formData.shortDescription}
                        onChange={handleChange}
                        className="form-textarea"
                        rows="2"
                        maxLength="150"
                        placeholder="Brief description (max 150 characters)"
                    />
                    {/* <small className="character-count">
                        {formData.shortDescription.length}/150 characters
                    </small> */}
                </div>
                <div className="form-group">
                    <label htmlFor="description">Full Description</label>
                    <textarea
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        className="form-textarea"
                        rows="4"
                        placeholder="Detailed description..."
                    />
                </div>
                <div className="form-group">
                    {/* <label htmlFor="programId">Programs</label> */}
                    <div className="selected-programs">
                        {formData.programId.map(id => {
                            const program = programs.find(p => p.idprograms === parseInt(id));
                            return program ? (
                                <span key={id} className="selected-program-tag">
                                    {program.name}
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setFormData(prev => ({
                                                ...prev,
                                                programId: prev.programId.filter(pId => pId !== id)
                                            }));
                                        }}
                                        className="tag-remove"
                                    >
                                        ×
                                    </button>
                                </span>
                            ) : null;
                        })}
                    </div>
                    {/* <select
                        id="programId"
                        name="programId"
                        value={formData.programId}
                        onChange={handleChange}
                        multiple
                        className="multi-select"
                    >
                        {programs && programs.map(program => (
                            <option key={program.idprograms} value={program.idprograms}>
                                {program.name}
                            </option>
                        ))}
                    </select> */}
                    {/* <small className="select-hint">
                        <i className="fas fa-info-circle"></i> 
                        {navigator.platform.includes('Mac') 
                            ? 'Hold Command (⌘) to select multiple programs' 
                            : 'Hold Ctrl to select multiple programs'}
                    </small> */}
                </div>

                <div className="form-group">
                    <label htmlFor="tags">Tags</label>
                    <div className="tags-input-container">
                        <div className="tags-list">
                            {tags.map(tag => (
                                <span key={tag} className="tag">
                                    {tag}
                                    <button
                                        type="button"
                                        onClick={() => removeTag(tag)}
                                        className="tag-remove"
                                    >
                                        ×
                                    </button>
                                </span>
                            ))}
                        </div>
                        <input
                            type="text"
                            value={tagInput}
                            onChange={handleTagInput}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    addTag(tagInput);
                                }
                            }}
                            placeholder="Add tags..."
                        />
                        {suggestions.length > 0 && (
                            <div className="tag-suggestions">
                                {suggestions.map(suggestion => (
                                    <div
                                        key={suggestion}
                                        className="tag-suggestion"
                                        onClick={() => addTag(suggestion)}
                                    >
                                        {suggestion}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="levelId">Intensity Level</label>
                    <select
                        id="levelId"
                        name="levelId"
                        value={formData.levelId}
                        onChange={handleChange}
                        className="form-select"
                    >
                        <option value="">Select a level</option>
                        {levels.map(level => (
                            <option 
                                key={level.idintensity_zone} 
                                value={level.idintensity_zone}
                                style={{
                                    backgroundColor: level.fargekode_bakgrunn,
                                    color: level.fargekode_tekst
                                }}
                            >
                                {level.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="stepId">Step</label>
                    <select
                        id="stepId"
                        name="stepId"
                        value={formData.stepId}
                        onChange={handleChange}
                        className="form-select"
                    >
                        <option value="">Select a step</option>
                        {steps.map(step => (
                            <option 
                                key={step.idsteps} 
                                value={step.idsteps}
                            >
                                {step.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="environmentId">Environment</label>
                    <select
                        id="environmentId"
                        name="environmentId"
                        value={formData.environmentId}
                        onChange={handleChange}
                        className="form-select"
                    >
                        <option value="">Select an environment</option>
                        {environments.map(env => (
                            <option 
                                key={env.idenvironment} 
                                value={env.idenvironment}
                            >
                                {env.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group toggle-group">
                    <label className="toggle-label">
                        <span className="toggle-text">Published Activity</span>
                        <i className="fas fa-info-circle tooltip-icon" 
                        data-tooltip="Published activities are visible to all users. Private activities are only visible to admins.">
                        </i>
                        <div className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={formData.isPublic}
                                onChange={(e) => setFormData({
                                    ...formData,
                                    isPublic: e.target.checked
                                })}
                            />
                            <span className="toggle-slider"></span>
                        </div>
                    
                    </label>
                 
                    <label className="toggle-label">
                        <span className="toggle-text">Free Activity</span>
                        <i className="fas fa-info-circle tooltip-icon" 
                           data-tooltip="Free activities are available to all users. Non-free activities require a subscription.">
                        </i>
                        <div className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={formData.isFree}
                                onChange={(e) => setFormData({
                                    ...formData,
                                    isFree: e.target.checked
                                })}
                            />
                            
                            <span className="toggle-slider"></span>
                        </div>
                      
                    </label>
                </div>

                <div className="form-group">
                    <label htmlFor="minutes">Minutes</label>
                    <input
                        type="number"
                        id="minutes"
                        name="minutes"
                        value={formData.minutes}
                        onChange={handleChange}
                        className="form-input"
                    />
                </div>

                <div className="form-group">
                    <label>Audio File:</label>
                    <MediaSelector 
                        onSelect={(url) => setAudioUrl(url)}
                        selectedUrl={audioUrl}
                    />
                </div>

                <div className="form-group">
                    <label>Video File:</label>
                    <MediaSelector 
                        onSelect={(url) => setVideoUrl(url)}
                        selectedUrl={videoUrl}
                        folder="video"
                        accept="video/*"
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="voicingLevel">Voicing Level</label>
                    <select
                        id="voicingLevel"
                        name="voicingLevel"
                        value={formData.voicingLevel}
                        onChange={handleChange}
                        className="form-select"
                    >
                        <option value="no">No Voicing</option>
                        <option value="some">Some Voicing</option>
                        <option value="consistent">Consistent Voicing</option>
                    </select>
                </div>

                <button type="submit" className="submit-button">Create Activity</button>
            </form>
            {msg && <div className="success-message">{msg}</div>}

            {/* Activities List */}
            <div className="activities-list">
                <h2>Existing Activities</h2>
                <div className="list-container">
                    {activities.length > 0 ? (
                        activities.map((activity) => (
                            <div key={activity.idactivity} className="list-item">
                                <div className="activity-info">
                                    {editingId === activity.idactivity ? (
                                        <div className="edit-form">
                                            {/* Row 1 */}
                                            <div className="edit-form-row">
                                                <div className="edit-form-col">
                                                    <label>Activity Name</label>
                                                    <input
                                                        type="text"
                                                        value={editData.activityName}
                                                        onChange={(e) => setEditData({
                                                            ...editData,
                                                            activityName: e.target.value
                                                        })}
                                                        className="edit-input"
                                                    />
                                                </div>
                                                {/* <div className="edit-form-col">
                                                    <label>Program</label>
                                                    <select
                                                        value={editData.programId}
                                                        onChange={(e) => setEditData({
                                                            ...editData,
                                                            programId: e.target.value
                                                        })}
                                                        className="edit-select"
                                                    >
                                                        {programs.map(program => (
                                                            <option key={program.idprograms} value={program.idprograms}>
                                                                {program.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div> */}
                                            </div>

                                            {/* Row 2 */}
                                            <div className="edit-form-row">
                                                <div className="edit-form-col">
                                                    <label>Level</label>
                                                    <select
                                                        value={editData.levelId}
                                                        onChange={(e) => setEditData({
                                                            ...editData,
                                                            levelId: e.target.value
                                                        })}
                                                        className="edit-select"
                                                    >
                                                        <option value="">{activity.level_name || "Select a level"}</option>
                                                        {levels.map(level => (
                                                            <option key={level.idintensity_zone} value={level.idintensity_zone}>
                                                                {level.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="edit-form-col">
                                                    <label>Step</label>
                                                    <select
                                                        value={editData.stepId === null ? "null" : (editData.stepId || '')}
                                                        onChange={(e) => setEditData({
                                                            ...editData,
                                                            stepId: e.target.value === "null" ? null : e.target.value
                                                        })}
                                                        className="edit-select"
                                                    >
                                                        <option value="null">Select a step</option>
                                                        {steps.map(step => (
                                                            <option 
                                                                key={step.idsteps} 
                                                                value={step.idsteps}
                                                            >
                                                                {step.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                            {/* Row 3 */}
                                            <div className="edit-form-row">
                                                <div className="edit-form-col">
                                                    <label>Environment</label>
                                                    <select
                                                        value={editData.environmentId}
                                                        onChange={(e) => setEditData({
                                                            ...editData,
                                                            environmentId: e.target.value
                                                        })}
                                                        className="edit-select"
                                                    >
                                                        <option value="">{activity.environment_name || "Select an environment"}</option>
                                                        {environments.map(env => (
                                                            <option key={env.idenvironment} value={env.idenvironment}>
                                                                {env.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="edit-form-col">
                                                    <label>Duration (minutes)</label>
                                                    <input
                                                        type="number"
                                                        value={editData.minutes}
                                                        onChange={(e) => setEditData({
                                                            ...editData,
                                                            minutes: e.target.value
                                                        })}
                                                        min="1"
                                                        className="edit-input"
                                                        placeholder="Enter duration in minutes"
                                                    />
                                                </div>
                                            </div>

                                            {/* Full width items */}
                                            <div className="edit-form-row">
                                                <div className="edit-form-col">
                                                    <label>Short Description</label>
                                                    <textarea
                                                        value={editData.shortDescription}
                                                        onChange={(e) => setEditData({
                                                            ...editData,
                                                            shortDescription: e.target.value
                                                        })}
                                                        className="edit-textarea"
                                                        placeholder="Brief description..."
                                                        rows="2"
                                                        maxLength="150"
                                                    />
                                                    <small className="character-count">
                                                        {/* {editData.shortDescription.length}/150 characters */}
                                                    </small>
                                                </div>
                                            </div>

                                            <div className="edit-form-row">
                                                <div className="edit-form-col">
                                                    <label>Full Description</label>
                                                    <textarea
                                                        value={editData.description}
                                                        onChange={(e) => setEditData({
                                                            ...editData,
                                                            description: e.target.value
                                                        })}
                                                        className="edit-textarea"
                                                        placeholder="Detailed description..."
                                                        rows="4"
                                                    />
                                                </div>
                                            </div>

                                            <div className="edit-form-row">
                                                <div className="edit-form-col">
                                                    <label>Audio File</label>
                                                    <div className="audio-controls">
                                                        {editData.audioUrl && (
                                                            <>
                                                                <button 
                                                                    type="button"
                                                                    className={`audio-button norwegian ${playingStates[`${editingId}-norwegian`] ? 'playing' : ''}`}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        handlePlayAudio(editingId, editData.audioUrl, 'norwegian');
                                                                    }}
                                                                >
                                                                    <i className={`fas ${playingStates[`${editingId}-norwegian`] ? 'fa-pause' : 'fa-play'}`}></i>
                                                                    {playingStates[`${editingId}-norwegian`] ? 'Pause Norwegian' : 'Play Norwegian'}
                                                                </button>
                                                                <button 
                                                                    type="button"
                                                                    className={`audio-button english ${playingStates[`${editingId}-english`] ? 'playing' : ''}`}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        handlePlayAudio(editingId, editData.audioUrl, 'english');
                                                                    }}
                                                                >
                                                                    <i className={`fas ${playingStates[`${editingId}-english`] ? 'fa-pause' : 'fa-play'}`}></i>
                                                                    {playingStates[`${editingId}-english`] ? 'Pause English' : 'Play English'}
                                                                </button>
                                                            </>
                                                        )}
                                                    </div>

                                                    <MediaSelector 
                                                        onSelect={(url) => setEditData({
                                                            ...editData,
                                                            audioUrl: url
                                                        })}
                                                        selectedUrl={editData.audioUrl}
                                                    />
                                                </div>
                                            </div>

                                            <div className="edit-form-row">
                                                <div className="edit-form-col">
                                                    <label>Video File</label>
                                                    <div className="video-preview">
                                                        {editData.videoUrl && (
                                                            <div className="video-container">
                                                                <video 
                                                                    controls 
                                                                    src={editData.videoUrl}
                                                                    className="preview-video"
                                                                >
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <MediaSelector 
                                                        onSelect={(url) => setEditData({
                                                            ...editData,
                                                            videoUrl: url
                                                        })}
                                                        selectedUrl={editData.videoUrl}
                                                        folder="video"
                                                        accept="video/*"
                                                    />
                                                </div>
                                            </div>

                                            <div className="edit-form-row">
                                                <div className="edit-form-col toggle-group">
                                                    <label className="toggle-label">
                                                        <span className="toggle-text">Published Activity</span>
                                                        <div className="toggle-switch">
                                                            <input
                                                                type="checkbox"
                                                                checked={editData.isPublic}
                                                                onChange={(e) => setEditData({
                                                                    ...editData,
                                                                    isPublic: e.target.checked
                                                                })}
                                                            />
                                                            <span className="toggle-slider"></span>
                                                        </div>
                                                    </label>

                                                    <label className="toggle-label" style={{ marginLeft: '2rem' }}>
                                                        <span className="toggle-text">Free Activity</span>
                                                        <div className="toggle-switch">
                                                            <input
                                                                type="checkbox"
                                                                checked={editData.isFree}
                                                                onChange={(e) => setEditData({
                                                                    ...editData,
                                                                    isFree: e.target.checked
                                                                })}
                                                            />
                                                            <span className="toggle-slider"></span>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="edit-form-row">
                                                <div className="edit-form-col">
                                                    <label>Voicing Level</label>
                                                    <select
                                                        value={editData.voicingLevel}
                                                        onChange={(e) => setEditData({
                                                            ...editData,
                                                            voicingLevel: e.target.value
                                                        })}
                                                        className="edit-select"
                                                    >
                                                        <option value="no">No Voicing</option>
                                                        <option value="some">Some Voicing</option>
                                                        <option value="consistent">Consistent of Voicing</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <h3>{activity.name}</h3>
                                            <p className="program-name">Program: {activity.program_name}</p>
                                            {activity.description && (
                                                <div className="activity-description-container">
                                                    <p className="activity-description">
                                                        {truncateText(activity.description, 120)}
                                                    </p>
                                                    {activity.description.length > 120 && (
                                                        <button 
                                                            className="show-more-button"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleEdit(activity);
                                                            }}
                                                        >
                                                            Show more
                                                        </button>
                                                    )}
                                                </div>
                                            )}
                                            
                                            {(activity.norwegianURL || activity.englishURL) && (
                                                <div className="audio-controls">
                                                    {activity.norwegianURL && (
                                                        <button 
                                                            className={`audio-button norwegian ${playingStates[`${activity.idactivity}-norwegian`] ? 'playing' : ''}`}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handlePlayAudio(activity.idactivity, activity.norwegianURL, 'norwegian');
                                                            }}
                                                        >
                                                            <i className={`fas ${playingStates[`${activity.idactivity}-norwegian`] ? 'fa-pause' : 'fa-play'}`}></i>
                                                            {playingStates[`${activity.idactivity}-norwegian`] ? 'Pause Norwegian' : 'Play Norwegian'}
                                                        </button>
                                                    )}
                                                    {activity.englishURL && (
                                                        <button 
                                                            className={`audio-button english ${playingStates[`${activity.idactivity}-english`] ? 'playing' : ''}`}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handlePlayAudio(activity.idactivity, activity.englishURL, 'english');
                                                            }}
                                                        >
                                                            <i className={`fas ${playingStates[`${activity.idactivity}-english`] ? 'fa-pause' : 'fa-play'}`}></i>
                                                            {playingStates[`${activity.idactivity}-english`] ? 'Pause English' : 'Play English'}
                                                        </button>
                                                    )}
                                                </div>
                                            )}

                                            <div className="activity-tags">
                                                {activity.tags && activity.tags.split(',').map((tag, index) => (
                                                    <span key={index} className="tag">
                                                        {tag.trim()}
                                                        <button
                                                            type="button"
                                                            onClick={async (e) => {
                                                                e.stopPropagation();
                                                                try {
                                                                    const updatedTags = activity.tags
                                                                        .split(',')
                                                                        .filter((_, i) => i !== index)
                                                                        .join(',');
                                                                    
                                                                    await axios.put(
                                                                        `${process.env.REACT_APP_BACKEND_URL}/api/activities/${activity.idactivity}`,
                                                                        {
                                                                            name: activity.name,
                                                                            programId: activity.idprograms,
                                                                            description: activity.description,
                                                                            tags: updatedTags ? updatedTags.split(',') : []
                                                                        },
                                                                        { withCredentials: true }
                                                                    );
                                                                    
                                                                    // Use fetchActivities instead of fetchInitialData
                                                                    await fetchActivities();
                                                                } catch (error) {
                                                                    console.error('Error removing tag:', error);
                                                                    setError('Failed to remove tag');
                                                                }
                                                            }}
                                                            className="tag-remove"
                                                        >
                                                            ×
                                                        </button>
                                                    </span>
                                                ))}
                                            </div>
                                            {activity.level_name && (
                                                <p className="activity-detail">
                                                    <strong>Level:</strong> {activity.level_name}
                                                </p>
                                            )}
                                            {activity.step_name && (
                                                <p className="activity-detail">
                                                    <strong>Step:</strong> {activity.step_name}
                                                </p>
                                            )}
                                            {activity.environment_name && (
                                                <p className="activity-detail">
                                                    <strong>Environment:</strong> {activity.environment_name}
                                                </p>
                                            )}
                                            <p className="activity-detail">
                                                <strong>Status:</strong> {activity.isPublic === 1 ? 'Published' : 'Draft'} | 
                                                {activity.isFree === 1 ? ' Free' : ' Pro'}
                                            </p>
                                            {activity.minutes && (
                                                <p className="activity-detail">
                                                    <strong>Duration:</strong> {activity.minutes} minutes
                                                </p>
                                            )}
                                            <p className="activity-detail">
                                                <strong>Voicing:</strong> {activity.voicing_level === 'no' ? 'No' : 
                                                                 activity.voicing_level === 'consistent' ? 'Consistent' : 'Normal'}
                                            </p>
                                            {/* Add video preview in activity list view */}
                                            {activity.videoUrl && (
                                                <div className="video-preview">
                                                    <div className="video-container">
                                                        <video 
                                                            controls 
                                                            src={activity.videoUrl}
                                                            className="preview-video"
                                                        >
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                                <div className="list-item-actions">
                                    {editingId === activity.idactivity ? (
                                        <>
                                            <button 
                                                className="save-button"
                                                onClick={() => handleUpdate(activity.idactivity)}
                                            >
                                                <i className="fas fa-save"></i>
                                            </button>
                                            <button 
                                                className="cancel-button"
                                                onClick={() => setEditingId(null)}
                                            >
                                                <i className="fas fa-times"></i>
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button 
                                                className="edit-button"
                                                onClick={() => handleEdit(activity)}
                                            >
                                                <i className="fas fa-pencil-alt"></i>
                                            </button>
                                            <button 
                                                className="delete-button"
                                                onClick={() => {
                                                    setDeleteConfirm(activity);
                                                }}
                                            >
                                                <i className="fas fa-trash-alt"></i>
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="list-item">
                            <span>No activities found</span>
                        </div>
                    )}
                </div>
            </div>
            {deleteConfirm && (
                <DeleteModal 
                    isOpen={!!deleteConfirm}
                    onClose={() => setDeleteConfirm(null)}
                    onConfirm={() => handleDelete(deleteConfirm.idactivity)}
                    itemName={`activity "${deleteConfirm.name}"`}
                />
            )}

            <style jsx>{`
                .audio-controls {
                    display: flex;
                    gap: 1rem;
                    margin-top: 1rem;
                    flex-wrap: wrap;
                }

                .audio-button {
                    padding: 0.5rem 1rem;
                    border: none;
                    border-radius: 6px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    font-size: 0.9rem;
                    transition: all 0.2s;
                }

                .audio-button.norwegian {
                    background-color: #e3f2fd;
                    color: #1976d2;
                }

                .audio-button.english {
                    background-color: #e8f5e9;
                    color: #2e7d32;
                }

                .audio-button.playing {
                    background-color: #f5f5f5;
                }

                .audio-button:hover {
                    filter: brightness(0.95);
                }

                .audio-button i {
                    font-size: 0.9rem;
                }

                .video-preview {
                    margin: 1rem 0;
                    width: 100%;
                }

                .video-container {
                    position: relative;
                    width: 100%;
                    max-width: 600px;
                    margin: 0 auto;
                    background: #f8f9fa;
                    border-radius: 8px;
                    overflow: hidden;
                }

                .preview-video {
                    width: 100%;
                    display: block;
                    border-radius: 8px;
                }

                .video-container video {
                    width: 100%;
                    height: auto;
                    display: block;
                }
            `}</style>
        </div>
    );
} 