import React, { useState } from 'react';
import '../../styles/components/forms.css';

const ActivitySelector = ({
    dayNumber,
    searchTerm = '',
    activities,
    selectedActivities,
    onSearchChange,
    onSearchKeyDown,
    onActivityAdd,
    onActivityRemove,
    singleSelect = false
}) => {
    const [localSearchTerm, setLocalSearchTerm] = useState('');

    const handleLocalSearchChange = (e) => {
        setLocalSearchTerm(e.target.value);
        if (onSearchChange) {
            onSearchChange(e);
        }
    };

    const handleActivitySelect = (activity) => {
        const selectedActivity = {
            ...activity,
            idactivity: activity.idactivity || activity.idactivities
        };
        onActivityAdd(selectedActivity);
        setLocalSearchTerm('');
    };

    const currentSearchTerm = (searchTerm || localSearchTerm || '').toLowerCase();

    return (
        <div className="activity-selector">
            <div className="activity-search">
                <label>{singleSelect ? 'Search for an activity' : `Search and Add Activities for Day ${dayNumber}`}</label>
                <input
                    type="text"
                    placeholder="Search activities..."
                    value={searchTerm || localSearchTerm}
                    onChange={handleLocalSearchChange}
                    onKeyDown={onSearchKeyDown}
                    className="search-input"
                />
                
                {(searchTerm || localSearchTerm) && (
                    <div className="search-results">
                        {activities
                            .filter(activity => 
                                activity.name.toLowerCase().includes(currentSearchTerm)
                            )
                            .map(activity => (
                                <div 
                                    key={activity.idactivity || activity.idactivities}
                                    onClick={() => handleActivitySelect(activity)}
                                    className="search-result-item"
                                >
                                    {activity.name}
                                </div>
                            ))
                        }
                    </div>
                )}
            </div>

            {!singleSelect && (
                <div className="selected-activities">
                    <h5>Selected Activities:</h5>
                    {selectedActivities.map(activity => (
                        <div 
                            key={activity.instanceId || activity.idactivity}
                            className="selected-activity-item"
                        >
                            <span>{activity.name}</span>
                            <button
                                type="button"
                                onClick={() => onActivityRemove(activity.instanceId || activity.idactivity)}
                                className="remove-activity-button"
                            >
                                <i className="fas fa-times"></i>
                            </button>
                        </div>
                    ))}
                    {selectedActivities.length === 0 && (
                        <p className="no-activities-message">
                            No activities selected for this day
                        </p>
                    )}
                </div>
            )}
            {singleSelect && selectedActivities.length > 0 && (
                <div className="selected-activity">
                    <div className="selected-activity-item">
                        <span>{selectedActivities[0].name}</span>
                        <button
                            type="button"
                            onClick={() => {
                                onActivityRemove();
                                setLocalSearchTerm('');
                            }}
                            className="remove-activity-button"
                        >
                            <i className="fas fa-times"></i>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ActivitySelector; 