import { useState } from "react";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import "../../styles/user/ProfileSubmission.css";
import "../../styles/components/forms.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const PRICE = 499 * 100;

const generateBlockId = (index) => {
    // Convert the index to a padded string (e.g., "000001")
    const paddedIndex = index.toString().padStart(6, '0');
    // Convert to base64 using browser APIs
    const blockId = btoa(`block-${paddedIndex}`);
    return blockId;
};

const CheckoutForm = ({ formData, files, onSuccess, onError }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [processingMessage, setProcessingMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);
    setProcessingMessage('Processing payment...');
    if (!stripe || !elements) {
      return;
    }

    try {
      // 1. Validate form first using elements.submit()
      const { error: submitError } = await elements.submit();
      if (submitError) {
        setErrorMessage(submitError.message);
        onError(submitError.message);
        setProcessing(false);
        return;
      }

      // 2. Create payment intent
      const intentResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/payment/create-intent`,
        { amount: PRICE }
      );

      const { clientSecret, paymentIntentId } = intentResponse.data;

      // 3. Confirm the payment
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${window.location.origin}/payment-complete`,
          payment_method_data: {
            billing_details: {
              name: formData.name,
              email: formData.email,
            },
          },
        },
        redirect: "if_required",
      });

      if (error) {
        setErrorMessage(error.message);
        onError(error.message);
      } else if (paymentIntent.status === "succeeded") {
        setProcessingMessage('Payment successful! Generating upload tokens...');

        // 4. Get upload tokens for the videos
        const tokenResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/join/generate-upload-tokens`,
          {
            numberOfVideos: files.length,
            email: formData.email,
            paymentIntentId,
            fileSizes: files.map(f => f.size)
          }
        );

        const { sasTokens } = tokenResponse.data;

        // 5. Upload videos directly to Azure
        setProcessingMessage('Uploading videos...');
        console.log("UPLOADING VIDEOS");
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const sasInfo = sasTokens[i];
          
          if (sasInfo.isChunkedUpload) {
            const chunkSize = 8 * 1024 * 1024; // 8MB chunks
            const chunks = Math.ceil(file.size / chunkSize);
            const blockIds = [];
            
            // Upload each chunk
            for (let j = 0; j < chunks; j++) {
              const start = j * chunkSize;
              const end = Math.min(file.size, start + chunkSize);
              const chunk = file.slice(start, end);
              const blockId = generateBlockId(j);
              blockIds.push(blockId);
              
              console.log("UPLOADING VIDEOS", blockIds);
              // Construct the proper URL for block upload
              const blockUrl = `https://${sasInfo.accountName}.blob.core.windows.net/${sasInfo.containerName}/${sasInfo.blobName}`;
              const blockUploadUrl = `${blockUrl}?${sasInfo.sasToken}&comp=block&blockid=${blockId}`;
              console.log("UPLOADING VIDEOS", blockUploadUrl);
              const response = await fetch(blockUploadUrl, {
                method: 'PUT',
                headers: {
                  'x-ms-blob-type': 'BlockBlob',
                  'Content-Type': 'application/octet-stream',
                  'x-ms-version': '2020-04-08',
                  'Origin': process.env.REACT_APP_FRONTEND_URL || window.location.origin,
                  'x-ms-date': new Date().toUTCString()
                },
                mode: 'cors',
                body: chunk
              });
              
              console.log("UPLOADING VIDEOS", response);
              if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to upload chunk ${j}: ${response.statusText}. Details: ${errorText}`);
              }
              console.log("UPLOADING VIDEOS",  `${i + 1}/${files.length} - ${Math.round((j + 1) * 100 / chunks)} %`);
              
              setProcessingMessage(`Uploading video ${i + 1}/${files.length} - ${Math.round((j + 1) * 100 / chunks)}%`);
            }
            
            // Commit all blocks
            const blockListXML = `<?xml version="1.0" encoding="utf-8"?>
            <BlockList>
                ${blockIds.map(id => `<Latest>${id}</Latest>`).join('\n        ')}
                </BlockList>`;

            // Construct the proper URL for block list
            const blockUrl = `https://${sasInfo.accountName}.blob.core.windows.net/${sasInfo.containerName}/${sasInfo.blobName}`;
            const commitUrl = `${blockUrl}?${sasInfo.sasToken}&comp=blocklist`;
            console.log("UPLOADING VIDEOS", blockUrl);
            console.log("UPLOADING VIDEOS", commitUrl);


            const commitResponse = await fetch(commitUrl, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/xml',
                'x-ms-version': '2020-04-08',
                'Origin': process.env.REACT_APP_FRONTEND_URL || window.location.origin,
                'x-ms-date': new Date().toUTCString()
              },
              mode: 'cors',
              body: blockListXML
            });

            if (!commitResponse.ok) {
              const errorText = await commitResponse.text();
              throw new Error(`Failed to commit blocks: ${commitResponse.statusText}. Details: ${errorText}`);
            }
          } else {
            console.log("UPLOADING VIDEOS FOR SMALL VIDEO", sasInfo.url);
            // Regular upload for smaller files
            const response = await fetch(sasInfo.url, {
              method: 'PUT',
              headers: {
                'x-ms-blob-type': 'BlockBlob',
                'Content-Type': file.type,
                'x-ms-version': '2020-04-08',
                'Origin': process.env.REACT_APP_FRONTEND_URL || window.location.origin,
                'x-ms-date': new Date().toUTCString()
              },
              mode: 'cors',
              credentials: 'omit',
              body: file
            });

            if (!response.ok) {
              const errorText = await response.text();
              throw new Error(`Failed to upload file: ${response.statusText}. Details: ${errorText}`);
            }
          }

          // Update progress message
          setProcessingMessage(`Uploaded ${i + 1} of ${files.length} videos...`);
        }

        // 6. Submit form data with video URLs
        setProcessingMessage('Finalizing submission...');
        const videoUrls = sasTokens.map(token => token.blobName);
        
        const submitResponse = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/join/submit`,
            {
                ...formData,
                paymentIntentId,
                videoUrl: videoUrls.join(','),
                numberOfVideos: files.length
            }
        );

        onSuccess();
      }
    } catch (err) {
      setErrorMessage(err.message);
      onError(err.message);
    } finally {
      setProcessing(false);
      setProcessingMessage('');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <PaymentElement />
      <button
        type="submit"
        disabled={!stripe || processing}
        className={`submit-button ${processing ? 'sending' : ''}`}
      >
        {processing ? processingMessage : `Pay ${PRICE / 100} kr and Submit`}
      </button>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </form>
  );
};

export default function Join() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    injuryHistory: "",
    goals: "",
    runningExperience: "",
    subscribeRunTechnique: false,
    subscribeNewApp: false,
  });
  const [files, setFiles] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showPayment, setShowPayment] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;


    setFormData((prev) => {
      const newData = {
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      };
      return newData;
    });
  };

  const handleFileSelect = (e) => {
    const selectedFiles = Array.from(e.target.files);
    validateAndSetFiles(selectedFiles);
  };

  const validateAndSetFiles = (fileList) => {
    const validFiles = fileList.filter((file) =>
      file.type.startsWith("video/")
    );

    if (validFiles.length !== fileList.length) {
      setError("Only video files are allowed");
      return;
    }

    setFiles(validFiles);
    setError("");
  };

  const removeFile = (index) => {
    setFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setShowPayment(true);
  };

  const handlePaymentSuccess = () => {
    setShowPayment(false);
    setSuccess("Thank you for your submission! We will contact you soon.");
    setFormData({
      name: "",
      email: "",
      phone: "",
      injuryHistory: "",
      goals: "",
      runningExperience: "",
      subscribeRunTechnique: false,
      subscribeNewApp: false,
    });
    setFiles([]);
  };


  // Add options for Stripe Elements
  const stripeOptions = {
    mode: "payment",
    amount: 499 * 100 , // 499 in cents
    currency: "nok",
    appearance: {
      theme: "stripe",
      variables: {
        colorPrimary: "#75B686",
        colorBackground: "#ffffff",
        colorText: "#333333",
      },
    },
  };

  return (
    <div className="profile-submission-container">
      <h1>Få vurdert din løpsteknikk</h1>
      <p className="intro-text">
        Fyll ut skjemaet under for å få vurdert din løpsteknikk. Jeg gir deg en
        tilbakemelding innen 2 arbeidshverdager.
      </p>
      <p className="intro-text">
       Du kan velge om du vil løpe inne på mølle eller ute. Bli filmet forfra, bakfra og fra siden. Løp i et normalt joggetempo. Altså ikke løp for fort. Det holder med ca 15 sekunder per video-klipp. 
      </p>
      <p className="intro-text">
      Målet med videoanalysen er å hjelpe deg til å løpe mer effektivt og lett på dine rolige løpeturer.      </p>
      <p className="intro-text">
        Du får nå videoanalyse til kampanjepris: 499kr (originalt 898kr), og vil også inkludere 1 mnd gratis tilgang til
        Løpeglad når appen lanseres.
      </p>
      {!showPayment ? (
        <form onSubmit={handleSubmit} className="profile-form">
          {error && <div className="error-message">{error}</div>}
          {success && <div className="success-message">{success}</div>}

          <div className="form-group">
            <label htmlFor="name">Fullt navn</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="phone">Telefonnummer</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="runningExperience">Løpserfaring</label>
            <textarea
              id="runningExperience"
              name="runningExperience"
              value={formData.runningExperience}
              onChange={handleChange}
              rows="4"
            />
          </div>

          <div className="form-group">
            <label htmlFor="injuryHistory">Skadehistorikk</label>
            <textarea
              id="injuryHistory"
              name="injuryHistory"
              value={formData.injuryHistory}
              onChange={handleChange}
              rows="4"
            />
          </div>

          <div className="form-group">
            <label htmlFor="goals">Mål</label>
            <textarea
              id="goals"
              name="goals"
              value={formData.goals}
              onChange={handleChange}
              rows="4"
            />
          </div>
        <p>Last opp 3 videoer her (husk å velge alle tre samtidig)</p>
          <div className="file-upload">
            <label className="upload-button">
              <i className="fas fa-cloud-upload-alt"></i>
              Last opp video
              <input
                type="file"
                accept="video/*"
                onChange={handleFileSelect}
                style={{ display: "none" }}
                multiple
              />
            </label>
            {files.map((file, index) => (
              <div key={index} className="selected-file">
                <span>{file.name}</span>
                <button
                  type="button"
                  className="remove-file"
                  onClick={() => removeFile(index)}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            ))}
          </div>

          <div className="form-group">
            <label className="container">
              <input
                type="checkbox"
                name="subscribeRunTechnique"
                checked={formData.subscribeRunTechnique}
                onChange={handleChange}
              />
              <span className="checkmark"></span>
              <span className="label-text">
                Abonner på Løpsteknikk-nyhetsbrev
              </span>
            </label>
          </div>

          <div className="form-group">
            <label className="container">
              <input
                type="checkbox"
                name="subscribeNewApp"
                checked={formData.subscribeNewApp}
                onChange={handleChange}
              />
              <span className="checkmark"></span>
              <span className="label-text">Abonner på ny app oppdateringer</span>
            </label>
          </div>

          <button type="submit" className="submit-button">
            Gå videre til betaling
          </button>
        </form>
      ) : (
        <Elements stripe={stripePromise} options={stripeOptions}>
          <CheckoutForm
            formData={formData}
            files={files}
            onSuccess={handlePaymentSuccess}
            onError={setError}
          />
        </Elements>
      )}
    </div>
  );
}
