import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import '../styles/translation.css';
import { Link } from 'react-router-dom';

const TranslationDashboard = () => {
  const [translations, setTranslations] = useState({ en: {}, no: {} });
  const [translatableContent, setTranslatableContent] = useState({});
  const [selectedTable, setSelectedTable] = useState('');
  const [editValues, setEditValues] = useState({});
  const { user } = useAuth();

  useEffect(() => {
    fetchTranslations();
    fetchTranslatableContent();
  }, []);

  const fetchTranslations = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/translations`);
      setTranslations(response.data);
      setEditValues(response.data);
    } catch (error) {
      console.error('Error fetching translations:', error);
    }
  };

  const fetchTranslatableContent = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/translations/content`,
        { withCredentials: true }
      );
      setTranslatableContent(response.data);
    } catch (error) {
      console.error('Error fetching translatable content:', error);
    }
  };

  const handleSubmit = async () => {
    try {
      const updates = [];
      Object.entries(editValues).forEach(([language, translations]) => {
        Object.entries(translations).forEach(([key, value]) => {
          if (key.startsWith(selectedTable)) {
            updates.push({ key, language, value });
          }
        });
      });

      for (const update of updates) {
        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/translations/update`,
          update,
          { withCredentials: true }
        );
      }

      fetchTranslations();
      alert('Translations updated successfully!');
    } catch (error) {
      console.error('Error updating translations:', error);
      alert('Error updating translations');
    }
  };

  const handleInitialize = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/translations/initialize`,
        {},
        { withCredentials: true }
      );
      fetchTranslations();
    } catch (error) {
      console.error('Error initializing translations:', error);
    }
  };

  const handleInputChange = (key, language, value) => {
    setEditValues(prev => ({
      ...prev,
      [language]: {
        ...prev[language],
        [key]: value
      }
    }));
  };

  const isTextArea = (field) => {
    return field.includes('description');
  };

  if (!user || user.role !== 'superadmin') {
    return <div>Access denied. Superadmin only.</div>;
  }

  const renderTranslationFields = (row) => {
    const fields = Object.entries(row).filter(([key]) => !key.startsWith('id'));
    const idField = Object.keys(row).find(key => key.startsWith('id'));

    return fields.map(([field, value]) => {
      const translationKey = `${selectedTable}_${row[idField]}_${field}`;
      return (
        <tr key={translationKey}>
          <td className="field-name">{field}</td>
          <td>
            {isTextArea(field) ? (
              <textarea
                value={editValues.no?.[translationKey] || value}
                onChange={(e) => handleInputChange(translationKey, 'no', e.target.value)}
                className="translation-textarea"
                placeholder="Norwegian translation"
              />
            ) : (
              <input
                type="text"
                value={editValues.no?.[translationKey] || value}
                onChange={(e) => handleInputChange(translationKey, 'no', e.target.value)}
                className="translation-input"
                placeholder="Norwegian translation"
              />
            )}
          </td>
          <td>
            {isTextArea(field) ? (
              <textarea
                value={editValues.en?.[translationKey] || ''}
                onChange={(e) => handleInputChange(translationKey, 'en', e.target.value)}
                className="translation-textarea"
                placeholder="English translation"
              />
            ) : (
              <input
                type="text"
                value={editValues.en?.[translationKey] || ''}
                onChange={(e) => handleInputChange(translationKey, 'en', e.target.value)}
                className="translation-input"
                placeholder="English translation"
              />
            )}
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="translation-dashboard">
      <div className="translation-header">
      <Link to="/dashboard" className="back-button">
          <i className="fas fa-arrow-left"></i> Back to Dashboard
        </Link>
        <h1>Translation Dashboard</h1>
        <button
          onClick={handleInitialize}
          className="initialize-button"
        >
          Initialize Translations
        </button>
      </div>

      <div className="translation-tables">
        <h2>Available Tables</h2>
        <div className="table-buttons">
          {Object.keys(translatableContent).map(table => (
            <button
              key={table}
              onClick={() => setSelectedTable(table)}
              className={`table-button ${selectedTable === table ? 'active' : ''}`}
            >
              {table}
            </button>
          ))}
        </div>
      </div>

      {selectedTable && translatableContent[selectedTable] && (
        <div className="translation-content">
          <div className="content-header">
            <h2>Table Content: {selectedTable}</h2>
            <button
              onClick={handleSubmit}
              className="save-button"
            >
              Save All Changes
            </button>
          </div>
          
          <div className="overflow-x-auto">
            {translatableContent[selectedTable].map((row, rowIndex) => (
              <div key={rowIndex} className="mb-8">
                <div className="item-header">
                  <h3>ID: {row[Object.keys(row).find(key => key.startsWith('id'))]}</h3>
                </div>
                <table className="translation-table">
                  <thead>
                    <tr>
                      <th>Field</th>
                      <th>Norwegian</th>
                      <th>English</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderTranslationFields(row)}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TranslationDashboard; 