import { Link, useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import DeleteModal from '../../components/DeleteModal';
export default function IndividualUser() {
    const { id } = useParams();
    const [user, setUser] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [editData, setEditData] = useState(null);
    const [roles, setRoles] = useState([]);
    const [businesses, setBusinesses] = useState([]);
    const [msg, setMsg] = useState('');
    const [pricingPlans, setPricingPlans] = useState([]);
    const [mailerLiteGroups, setMailerLiteGroups] = useState([]);
    const [selectedMailerLiteGroup, setSelectedMailerLiteGroup] = useState('');
    const [showMailerLiteForm, setShowMailerLiteForm] = useState(false);
    const [userGroups, setUserGroups] = useState([]);
    const [deleteConfirm, setDeleteConfirm] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        fetchUser();
        fetchRoles();
        fetchBusinesses();
        fetchPricingPlans();
        fetchMailerLiteGroups();
        if (user?.email) {
            fetchUserGroups();
        }
    }, [id, user?.email]);

    const fetchUser = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/users/${id}`,
                { withCredentials: true }
            );
            setUser(response.data);
            setEditData(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching user:', error);
            setError('Failed to load user');
            setLoading(false);
        }
    };

    const fetchRoles = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/users/roles`,
                { withCredentials: true }
            );
            setRoles(response.data);
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    };

    const fetchBusinesses = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/businesses`,
                { withCredentials: true }
            );
            setBusinesses(response.data);
        } catch (error) {
            console.error('Error fetching businesses:', error);
        }
    };

    const fetchPricingPlans = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/pricing-plans`,
                { withCredentials: true }
            );
            setPricingPlans(response.data);
        } catch (error) {
            console.error('Error fetching pricing plans:', error);
        }
    };

    const fetchMailerLiteGroups = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/mailerlite/groups`,
                { withCredentials: true }
            );
            setMailerLiteGroups(response.data.data.data);
        } catch (error) {
            console.error('Error fetching MailerLite groups:', error);
            setError('Failed to fetch newsletter groups');
        }
    };

    const fetchUserGroups = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/mailerlite/subscribers/${encodeURIComponent(user.email)}/groups`,
                { withCredentials: true }
            );
            setUserGroups(response.data.data);
        } catch (error) {
            console.error('Error fetching user groups:', error);
        }
    };

    const handleEdit = () => {
        setEditData({
            ...user,
            pricing_id: user.pricing_id || null
        });
        setIsEditing(true);
    };

    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BACKEND_URL}/api/users/${id}`,
                { withCredentials: true }
            );
            if (response.status === 200) {
                setDeleteConfirm(null);
                navigate('/users');
            }
        } catch (error) {
            console.error('Error deleting user:', error);
        }
   
    };

    const handleCancel = () => {
        setIsEditing(false);
        setEditData({
            ...user,
            pricing_id: user.pricing_id || null
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditData(prev => ({
            ...prev,
            [name]: value === "null" ? null : value,
            ...(name === 'businessId' && {
                businessId: value,
                businessName: value ? businesses.find(b => b.idbusiness === parseInt(value))?.name : '',
                pricing_id: value ? null : prev.pricing_id
            }),
            ...(name === 'phone_number' && {
                phone_number: value
            })
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const [firstName, lastName] = editData.name.split(' ');
            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/api/users/${id}`,
                {
                    firstName,
                    lastName,
                    email: editData.email,
                    role: editData.role,
                    phone: editData.phone_number,
                    businessId: editData.businessId || null,
                    pricingId: (!editData.businessId && editData.pricing_id !== "null") 
                        ? editData.pricing_id 
                        : null
                },
                { withCredentials: true }
            );

            if (response.status === 200) {
                setMsg('User updated successfully');
                if (response.data.checkoutUrl) {
                    if (response.data.clientSecret) {
                        localStorage.setItem('stripe_client_secret', response.data.clientSecret);
                    }
                    window.location.href = response.data.checkoutUrl;
                } else {
                    setIsEditing(false);
                    fetchUser();
                    setTimeout(() => setMsg(''), 3000);
                }
            }
        } catch (error) {
            console.error('Error updating user:', error);
            setError(error.response?.data?.message || 'Failed to update user');
            setTimeout(() => setError(''), 3000);
        }
    };

    const assignToMailerLiteGroup = async (e) => {
        e.preventDefault();
        try {
            await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/mailerlite/subscribers`,
                {
                    email: editData.email,
                    name: editData.name,
                    groupId: selectedMailerLiteGroup
                },
                { withCredentials: true }
            );
            setMsg('User assigned to newsletter group successfully');
            setShowMailerLiteForm(false);
            setSelectedMailerLiteGroup('');
        } catch (error) {
            console.error('Error assigning to newsletter group:', error);
            setError('Failed to assign user to newsletter group');
        }
    };

    const removeSubscriber = async (email, groupId) => {
        if (window.confirm('Are you sure you want to remove this user from the group?')) {
            try {
                await axios.delete(
                    `${process.env.REACT_APP_BACKEND_URL}/api/mailerlite/groups/${groupId}/subscribers/${email}`,
                    { withCredentials: true }
                );
                setMsg('User removed from group successfully');
                fetchUserGroups(); // Refresh the groups list
            } catch (error) {
                setError('Failed to remove user from group');
                console.error('Error removing from group:', error);
            }
        }
    };

    if (loading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error-message">{error}</div>;
    if (!user) return <div className="error-message">User not found</div>;

    return (
        <div className="page-container">
            <div className="page-header-stack">
                <Link to="/users" className="back-button">
                    <i className="fas fa-arrow-left"></i> Back to Users
                </Link>
                <h1>User Details</h1>
            </div>

            {msg && <div className="success-message">{msg}</div>}

            <div className="detail-card">
                {isEditing ? (
                    <form onSubmit={handleSubmit} className="edit-form">
                        <div className="form-group">
                            <label>Name:</label>
                            <input
                                type="text"
                                name="name"
                                value={editData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label>Email:</label>
                            <input
                                type="email"
                                name="email"
                                value={editData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label>Phone Number:</label>
                            <input
                                type="tel"
                                name="phone_number"
                                value={editData.phone_number || ''}
                                onChange={handleChange}
                                placeholder="+47 XXX XX XXX"
                            />
                        </div>

                        <div className="form-group">
                            <label>Role:</label>
                            <select
                                name="role"
                                value={editData.role}
                                onChange={handleChange}
                                required
                            >
                                {roles.map(role => (
                                    <option key={role.idrole} value={role.role}>
                                        {role.role}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <label>Business:</label>
                            <select
                                name="businessId"
                                value={editData.businessId || ''}
                                onChange={handleChange}
                            >
                                <option value="">No business</option>
                                {businesses.map(business => (
                                    <option 
                                        key={business.idbusiness} 
                                        value={business.idbusiness}
                                    >
                                        {business.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {!editData.businessId && (
                            <div className="form-group">
                                <label>Pricing Plan:</label>
                                <select
                                    name="pricing_id"
                                    value={editData.pricing_id === null ? "null" : (editData.pricing_id || '')}
                                    onChange={handleChange}
                                    className="form-select"
                                >
                                    <option value="">Select a pricing plan</option>
                                    <option value="null">Free Plan</option>
                                    {pricingPlans.map(pricing => {
                                        let priceDisplay = '';
                                        if (pricing.tiers_mode) {
                                            const lowestTier = pricing.tiers && pricing.tiers[0];
                                            if (lowestTier) {
                                                priceDisplay = `from ${pricing.currency?.toUpperCase() || 'NOK'} ${lowestTier.amount}`;
                                            }
                                        } else {
                                            priceDisplay = `${pricing.currency?.toUpperCase() || 'NOK'} ${pricing.price}`;
                                        }

                                        return (
                                            <option 
                                                key={pricing.idpricing} 
                                                value={pricing.idpricing}
                                            >
                                                {pricing.name} - {priceDisplay} / {pricing.payment_interval || 'month'}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        )}

                        <div className="newsletter-section">
                            <h3>Newsletter Management</h3>
                            {!showMailerLiteForm ? (
                                <button
                                    type="button"
                                    className="secondary-button"
                                    onClick={() => setShowMailerLiteForm(true)}
                                >
                                    <i className="fas fa-envelope"></i> Assign to Newsletter Group
                                </button>
                            ) : (
                                <div className="newsletter-form">
                                    <div className="form-group">
                                        <label>Select Newsletter Group:</label>
                                        <select
                                            value={selectedMailerLiteGroup}
                                            onChange={(e) => setSelectedMailerLiteGroup(e.target.value)}
                                        >
                                            <option value="">Select a group</option>
                                            {mailerLiteGroups.map(group => (
                                                <option key={group.id} value={group.id}>
                                                    {group.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-actions">
                                        <button
                                            type="button"
                                            className="save-button"
                                            onClick={assignToMailerLiteGroup}
                                        >
                                            Assign to Group
                                        </button>
                                        <button
                                            type="button"
                                            className="cancel-button"
                                            onClick={() => {
                                                setShowMailerLiteForm(false);
                                                setSelectedMailerLiteGroup('');
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="form-actions">
                            <button type="submit" className="save-button">
                                Save Changes
                            </button>
                            <button 
                                type="button" 
                                className="cancel-button"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                ) : (
                    <>
                        <div className="detail-header">
                            <h2>{user.name}</h2>
                            <span className="role-badge">{user.role}</span>
                        </div>
                        
                        <div className="detail-content">
                            <div className="detail-row">
                                <label>Email:</label>
                                <span>{user.email}</span>
                            </div>
                            
                            {user.phone_number && (
                                <div className="detail-row">
                                    <label>Phone:</label>
                                    <span>{user.phone_number}</span>
                                </div>
                            )}
                            
                            {user.businessName && (
                                <div className="detail-row">
                                    <label>Business:</label>
                                    <span>{user.businessName}</span>
                                </div>
                            )}
                            
                            {!user.businessName && (
                                <div className="detail-row">
                                    <label>Pricing Plan:</label>
                                    <span>
                                        {user.pricingId === null ? 'Free Plan' : 
                                            // (pricingPlans.find(p => p.idpricing === parseInt(user.p))?.name || 'Loading...')}
                                            user.pricingName || 'Loading...'
                                            }
                                    </span>
                                </div>
                            )}
                            
                            <div className="detail-row">
                                <label>Created:</label>
                                <span>{new Date(user.created_at).toLocaleDateString()}</span>
                            </div>

                            <button 
                                className="edit-button"
                                onClick={handleEdit}
                            >
                                <i className="fas fa-pencil-alt"></i> Edit User
                            </button>

                        <button 
                            className="delete-button"
                            onClick={() => setDeleteConfirm(user)}
                            >
                            <i className="fas fa-trash-alt"></i> Delete User
                        </button>
                        </div>

                        <div className="newsletter-section">
                            <h3>Newsletter Groups</h3>
                            {userGroups.length > 0 ? (
                                <div className="groups-list">
                                    {userGroups.map(group => (
                                        <div key={group.id} className="group-badge">
                                            <span>{group.name}</span>
                                            <button
                                                onClick={() => removeSubscriber(user.email, group.id)}
                                                className="remove-group-button"
                                                title="Remove from group"
                                            >
                                                <i className="fas fa-times"></i>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p className="no-groups">Not subscribed to any newsletter groups</p>
                            )}
                        </div>
                    </>
                )}
            </div>
            {deleteConfirm && (
                <DeleteModal
                    isOpen={!!deleteConfirm}
                    onClose={() => setDeleteConfirm(null)}
                    onConfirm={() => handleDelete(deleteConfirm.idusers)}
                    itemName={`user "${deleteConfirm.name}"`}
                />
            )}
        </div>
    );
} 