import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';

export default function IndividualActivity() {
    const { id } = useParams();
    const [activity, setActivity] = useState(null);
    const [playlists, setPlaylists] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [audioPlayingNorwegian, setAudioPlayingNorwegian] = useState(null);
    const [audioPlayingEnglish, setAudioPlayingEnglish] = useState(null);
    const [isPlayingNorwegian, setIsPlayingNorwegian] = useState(false);
    const [isPlayingEnglish, setIsPlayingEnglish] = useState(false);

    useEffect(() => {
        fetchActivity();
        // Cleanup audio on unmount
        return () => {
            if (audioPlayingNorwegian) {
                audioPlayingNorwegian.pause();
            }
            if (audioPlayingEnglish) {
                audioPlayingEnglish.pause();
            }
        };
    }, [id]);

    const fetchActivity = async () => {
        try {
            const [activityResponse, playlistsResponse] = await Promise.all([
                axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/activities/${id}`,
                    { withCredentials: true }
                ),
                axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/activities/${id}/playlists`,
                    { withCredentials: true }
                )
            ]);
            
            setActivity(activityResponse.data);
            setPlaylists(playlistsResponse.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching activity:', error);
            setError('Failed to load activity');
            setLoading(false);
        }
    };

    const handlePlayAudio = (url, language) => {
        if (language === "norwegian") {
            if (audioPlayingNorwegian && isPlayingNorwegian) {
                audioPlayingNorwegian.pause();
                setIsPlayingNorwegian(false);
            } else {
                if (audioPlayingNorwegian) {
                    audioPlayingNorwegian.play();
                } else {
                    const audio = new Audio(url);
                    audio.addEventListener('ended', () => setIsPlayingNorwegian(false));
                    audio.play();
                    setAudioPlayingNorwegian(audio);
                }
                setIsPlayingNorwegian(true);
            }
            // Pause English audio if playing
            if (audioPlayingEnglish && isPlayingEnglish) {
                audioPlayingEnglish.pause();
                setIsPlayingEnglish(false);
            }
        } else if (language === "english") {
            if (audioPlayingEnglish && isPlayingEnglish) {
                audioPlayingEnglish.pause();
                setIsPlayingEnglish(false);
            } else {
                if (audioPlayingEnglish) {
                    audioPlayingEnglish.play();
                } else {
                    const audio = new Audio(url);
                    audio.addEventListener('ended', () => setIsPlayingEnglish(false));
                    audio.play();
                    setAudioPlayingEnglish(audio);
                }
                setIsPlayingEnglish(true);
            }
            // Pause Norwegian audio if playing
            if (audioPlayingNorwegian && isPlayingNorwegian) {
                audioPlayingNorwegian.pause();
                setIsPlayingNorwegian(false);
            }
        }
    };

    if (loading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error-message">{error}</div>;
    if (!activity) return <div className="error-message">Activity not found</div>;

    return (
        <div className="page-container">
            <div className="page-header-stack">
                <Link to="/activities" className="back-button">
                    <i className="fas fa-arrow-left"></i> Back to Activities
                </Link>
                <h1>{activity.name}</h1>
            </div>

            <div className="detail-card">
                {/* Main Activity Info */}
                <div className="activity-header">
                    <div className="activity-status">
                        {activity.isPublic ? (
                            <span className="status-badge published">
                                <i className="fas fa-globe"></i> Published
                            </span>
                        ) : (
                            <span className="status-badge private">
                                <i className="fas fa-lock"></i> Draft
                            </span>
                        )}
                        {activity.isFree ? (
                            <span className="status-badge free">
                                <i className="fas fa-gift"></i> Free
                            </span>
                        ) : (
                            <span className="status-badge premium">
                                <i className="fas fa-star"></i> Premium
                            </span>
                        )}
                    </div>
                </div>

                {/* Activity Details */}
                <div className="activity-details">
                    <div className="detail-section">
                        <h3>Activity Information</h3>
                        <div className="info-grid">
                            <div className="info-item">
                                <i className="fas fa-clock"></i>
                                <span>Duration: {activity.minutes} minutes</span>
                            </div>
                            <div className="info-item">
                                <i className="fas fa-running"></i>
                                <span>Steps Level: {activity.stepsName}</span>
                            </div>
                            <div className="info-item">
                                <i className="fas fa-mountain"></i>
                                <span>Environment: {activity.environmentName}</span>
                            </div>
                            <div className="info-item">
                                <i className="fas fa-signal"></i>
                                <span>Intensity Zone: {activity.intensityName}</span>
                            </div>
                            <div className="info-item">
                                <i className="fas fa-volume-up"></i>
                                <span>Voicing Level: {activity.voicing_level}</span>
                            </div>
                        </div>
                    </div>

                    {/* Description Section */}
                    <div className="detail-section">
                        <h3>Description</h3>
                        <p className="description-text">{activity.description}</p>
                        {activity.short_description && (
                            <p className="short-description">{activity.short_description}</p>
                        )}
                    </div>

                    {/* Audio Section */}
                    <div className="detail-section">
                        <h3>Audio</h3>
                        <div className="audio-controls">
                            {activity.norwegianURL && (
                                <div className="audio-item">
                                    <button 
                                        className={`audio-button norwegian ${isPlayingNorwegian ? 'playing' : ''}`}
                                        onClick={() => handlePlayAudio(activity.norwegianURL, 'norwegian')}
                                    >
                                        <i className={`fas ${isPlayingNorwegian ? 'fa-pause' : 'fa-play'}`}></i>
                                        {isPlayingNorwegian ? 'Pause Norwegian Version' : 'Play Norwegian Version'}
                                    </button>
                                </div>
                            )}
                            {activity.englishURL && (
                                <div className="audio-item">
                                    <button 
                                        className={`audio-button english ${isPlayingEnglish ? 'playing' : ''}`}
                                        onClick={() => handlePlayAudio(activity.englishURL, 'english')}
                                    >
                                        <i className={`fas ${isPlayingEnglish ? 'fa-pause' : 'fa-play'}`}></i>
                                        {isPlayingEnglish ? 'Pause English Version' : 'Play English Version'}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Video Section */}
                    {activity.videoUrl && (
                        <div className="detail-section">
                            <h3>Video</h3>
                            <div className="video-container">
                                <video 
                                    controls 
                                    src={activity.videoUrl}
                                    className="activity-video"
                                >
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    )}

                    {/* Playlists Section */}
                    <div className="detail-section">
                        <h3>Associated Playlists</h3>
                        <div className="playlists-grid">
                            {playlists.length > 0 ? (
                                playlists.map(playlist => (
                                    <div key={playlist.idplaylists} className="playlist-card">
                                        <div className="playlist-info">
                                            <h4>{playlist.name}</h4>
                                            <span className="playlist-duration">
                                                <i className="fas fa-clock"></i> {playlist.duration} minutes
                                            </span>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="no-playlists">No playlists associated with this activity</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <style jsx>{`
                .activity-header {
                    margin-bottom: 2rem;
                }

                .activity-status {
                    display: flex;
                    gap: 1rem;
                    margin-top: 1rem;
                }

                .status-badge {
                    padding: 0.5rem 1rem;
                    border-radius: 20px;
                    font-size: 0.9rem;
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                }

                .status-badge.published {
                    background-color: #e6f4ea;
                    color: #1e7e34;
                }

                .status-badge.private {
                    background-color: #fff3e0;
                    color: #e65100;
                }

                .status-badge.free {
                    background-color: #e3f2fd;
                    color: #1976d2;
                }

                .status-badge.premium {
                    background-color: #fdf2e9;
                    color: #c17d10;
                }

                .detail-section {
                    margin-bottom: 2rem;
                    padding: 1.5rem;
                    background: white;
                    border-radius: 8px;
                    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
                }

                .detail-section h3 {
                    color: #2c3e50;
                    margin-bottom: 1rem;
                    padding-bottom: 0.5rem;
                    border-bottom: 2px solid #eee;
                }

                .info-grid {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                    gap: 1rem;
                }

                .info-item {
                    display: flex;
                    align-items: center;
                    gap: 0.75rem;
                    padding: 0.75rem;
                    background: #f8f9fa;
                    border-radius: 6px;
                }

                .info-item i {
                    color: #666;
                    font-size: 1.1rem;
                }

                .description-text {
                    font-size: 1.1rem;
                    line-height: 1.6;
                    color: #2c3e50;
                }

                .short-description {
                    margin-top: 1rem;
                    font-size: 0.95rem;
                    color: #666;
                    font-style: italic;
                }

                .audio-controls {
                    display: flex;
                    gap: 1rem;
                    flex-wrap: wrap;
                }

                .audio-button {
                    padding: 0.75rem 1.5rem;
                    border: none;
                    border-radius: 6px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    font-size: 0.95rem;
                    transition: all 0.2s;
                }

                .audio-button.norwegian {
                    background-color: #e3f2fd;
                    color: #1976d2;
                }

                .audio-button.english {
                    background-color: #e8f5e9;
                    color: #2e7d32;
                }

                .audio-button.playing {
                    background-color: #f5f5f5;
                }

                .audio-button:hover {
                    filter: brightness(0.95);
                }

                .video-container {
                    width: 100%;
                    max-width: 800px;
                    margin: 0 auto;
                }

                .activity-video {
                    width: 100%;
                    border-radius: 8px;
                }

                .playlists-grid {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
                    gap: 1rem;
                }

                .playlist-card {
                    background: #f8f9fa;
                    padding: 1rem;
                    border-radius: 6px;
                    border: 1px solid #eee;
                    transition: transform 0.2s;
                }

                .playlist-card:hover {
                    transform: translateY(-2px);
                }

                .playlist-info h4 {
                    margin: 0 0 0.5rem 0;
                    color: #2c3e50;
                }

                .playlist-duration {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    color: #666;
                    font-size: 0.9rem;
                }

                .no-playlists {
                    color: #666;
                    font-style: italic;
                    text-align: center;
                    padding: 1rem;
                }
            `}</style>
        </div>
    );
}