import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../../styles/components/forms.css';
import "../../styles/user/MailerLite.css"

export default function MailerLite() {
    const [groups, setGroups] = useState([]);
    const [newGroupName, setNewGroupName] = useState('');
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [subscribers, setSubscribers] = useState([]);
    const [newSubscriber, setNewSubscriber] = useState({ email: '', name: '' });
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [newAppSubscribers, setNewAppSubscribers] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [campaignData, setCampaignData] = useState({
        name: '',
        subject: '',
        fromName: '',
        fromEmail: '',
        content: '',
        selectedGroups: [],
        type: 'regular', // or 'ab' for A/B testing
        language_id: 4,  // default to Norwegian
    });

    useEffect(() => {
        fetchGroups();
        fetchNewAppSubscribers();
    }, []);

    const fetchGroups = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/mailerlite/groups`,
                { withCredentials: true }
            );
            setGroups(response.data.data.data);
        } catch (error) {
            setError('Failed to fetch groups');
            console.error('Error fetching groups:', error);
        }
    };

    const fetchGroupSubscribers = async (groupId) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/mailerlite/groups/${groupId}/subscribers`,
                { withCredentials: true }
            );
            const subscribersWithGroup = response.data.data.data.map(subscriber => ({
                ...subscriber,
                groupId: groupId
            }));
            setSubscribers(subscribersWithGroup);
            setSelectedGroup(groupId);
        } catch (error) {
            setError('Failed to fetch subscribers');
            console.error('Error fetching subscribers:', error);
        }
    };

    const createGroup = async (e) => {
        e.preventDefault();
        try {
            await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/mailerlite/groups`,
                { name: newGroupName },
                { withCredentials: true }
            );
            setMessage('Group created successfully');
            setNewGroupName('');
            fetchGroups();
        } catch (error) {
            setError('Failed to create group');
            console.error('Error creating group:', error);
        }
    };

    const deleteGroup = async (groupId) => {
        if (window.confirm('Are you sure you want to delete this group?')) {
            try {
                await axios.delete(
                    `${process.env.REACT_APP_BACKEND_URL}/api/mailerlite/groups/${groupId}`,
                    { withCredentials: true }
                );
                setMessage('Group deleted successfully');
                fetchGroups();
                if (selectedGroup === groupId) {
                    setSelectedGroup(null);
                    setSubscribers([]);
                }
            } catch (error) {
                setError('Failed to delete group');
                console.error('Error deleting group:', error);
            }
        }
    };

    const addSubscriber = async (e) => {
        e.preventDefault();
        if (!selectedGroup) {
            setError('Please select a group first');
            return;
        }

        try {
            await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/mailerlite/subscribers`,
                {
                    ...newSubscriber,
                    groupId: selectedGroup
                },
                { withCredentials: true }
            );
            setMessage('Subscriber added successfully');
            setNewSubscriber({ email: '', name: '' });
            fetchGroupSubscribers(selectedGroup);
        } catch (error) {
            setError('Failed to add subscriber');
            console.error('Error adding subscriber:', error);
        }
    };

    const removeSubscriber = async (email, groupId) => {
        if (window.confirm('Are you sure you want to remove this subscriber?')) {
            try {
                await axios.delete(
                    `${process.env.REACT_APP_BACKEND_URL}/api/mailerlite/groups/${groupId}/subscribers/${email}`,
                    { withCredentials: true }
                );
                setMessage('Subscriber removed successfully');
                fetchGroupSubscribers(groupId);
            } catch (error) {
                setError('Failed to remove subscriber');
                console.error('Error removing subscriber:', error);
            }
        }
    };

    const fetchNewAppSubscribers = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/mailerlite/newapp/subscribers`,
                { withCredentials: true }
            );
            setNewAppSubscribers(response.data.data);
        } catch (error) {
            setError('Failed to fetch newApp subscribers');
            console.error('Error:', error);
        }
    };

    const handleCreateCampaign = async (e) => {
        e.preventDefault();
        try {
            const params = {
                name: campaignData.name,
                language_id: campaignData.language_id,
                type: campaignData.type,
                emails: [{
                    subject: campaignData.subject,
                    from_name: campaignData.fromName,
                    from: campaignData.fromEmail,
                    content: campaignData.content
                }],
                groups: campaignData.selectedGroups
            };

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/mailerlite/campaigns`,
                params,
                { withCredentials: true }
            );

            setMessage('Campaign created successfully');
            setCampaignData({
                name: '',
                subject: '',
                fromName: '',
                fromEmail: '',
                content: '',
                selectedGroups: [],
                type: 'regular',
                language_id: 4
            });
        } catch (error) {
            setError('Failed to create campaign');
            console.error('Error:', error);
        }
    };

    const ImageModal = ({ image, onClose }) => {
        return (
            <div className="image-modal-overlay" onClick={onClose}>
                <div className="image-modal-content" onClick={e => e.stopPropagation()}>
                    <button className="modal-close-button" onClick={onClose}>
                        <i className="fas fa-times"></i>
                    </button>
                    <img src={image.url} alt="Full size" />
                    <div className="image-info">
                        <p>Uploaded: {new Date(image.createdOn).toLocaleDateString()}</p>
                        <p>Type: {image.contentType}</p>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="mailerlite-container">
            <div className="page-header">
                <Link to="/dashboard" className="back-button">
                    <i className="fas fa-arrow-left"></i> Back to Dashboard
                </Link>
                <h1>Newsletter Management</h1>
            </div>

            {message && <div className="success-message">{message}</div>}
            {error && <div className="error-message">{error}</div>}

            <div className="mailerlite-grid">
                <div className="groups-section">
                    <h2>Newsletter Groups</h2>
                    <form onSubmit={createGroup} className="create-group-form">
                        <input
                            type="text"
                            value={newGroupName}
                            onChange={(e) => setNewGroupName(e.target.value)}
                            placeholder="New group name"
                            required
                        />
                        <button type="submit">Create Group</button>
                    </form>

                    <div className="groups-list">
                        {groups.map(group => (
                            <div key={group.id} className="group-item">
                                <div className="group-info">
                                    <h3>{group.name}</h3>
                                    <p>Total subscribers: {group.total}</p>
                                </div>
                                <div className="group-actions">
                                    <button
                                        onClick={() => fetchGroupSubscribers(group.id)}
                                        className="view-button"
                                    >
                                        View Subscribers
                                    </button>
                                    <button
                                        onClick={() => deleteGroup(group.id)}
                                        className="delete-button"
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {selectedGroup && (
                    <div className="subscribers-section">
                        <h2>Subscribers</h2>
                        <form onSubmit={addSubscriber} className="add-subscriber-form">
                            <input
                                type="email"
                                value={newSubscriber.email}
                                onChange={(e) => setNewSubscriber(prev => ({ ...prev, email: e.target.value }))}
                                placeholder="Email"
                                required
                            />
                            <input
                                type="text"
                                value={newSubscriber.name}
                                onChange={(e) => setNewSubscriber(prev => ({ ...prev, name: e.target.value }))}
                                placeholder="Name"
                                required
                            />
                            <button type="submit">Add Subscriber</button>
                        </form>

                        <div className="subscribers-list">
                            {subscribers.map(subscriber => (
                                <div key={subscriber.id} className="subscriber-item">
                                    <div className="subscriber-info">
                                        {subscriber.image_url && (
                                            <div className="subscriber-image">
                                                <img src={subscriber.image_url} alt={subscriber.name} />
                                            </div>
                                        )}
                                        <div className="subscriber-details">
                                            <h4>{subscriber.name}</h4>
                                            <p>{subscriber.email}</p>
                                            {subscriber.phone && <p className="phone">{subscriber.phone}</p>}
                                            {subscriber.experience && (
                                                <p className="experience-level">
                                                    Experience: {subscriber.experience}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <button
                                        onClick={() => removeSubscriber(subscriber.email, selectedGroup)}
                                        className="remove-button"
                                    >
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>

            <div className="newapp-section">
                <h2>New App Subscribers</h2>
                <div className="newapp-subscribers-grid">
                    {newAppSubscribers.map(subscriber => (
                        <div key={subscriber.id} className="newapp-subscriber-card">
                            <div className="subscriber-info">
                                <h3>{subscriber.fields?.name || 'No Name'}</h3>
                                <p>{subscriber.email}</p>
                                {subscriber.fields?.phone && (
                                    <p className="phone">{subscriber.fields.phone}</p>
                                )}
                            </div>
                            {subscriber.images && subscriber.images.length > 0 && (
                                <div className="subscriber-images">
                                    {subscriber.images.map((image, index) => (
                                        <img 
                                            key={index}
                                            src={image.url}
                                            alt={`${subscriber.fields?.name || 'Subscriber'}'s image`}
                                            className="subscriber-image"
                                            onClick={() => setSelectedImage(image)}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            <div className="campaign-section">
                <h2>Create Email Campaign</h2>
                <form onSubmit={handleCreateCampaign} className="campaign-form">
                    <div className="form-group">
                        <label>Campaign Name</label>
                        <input
                            type="text"
                            value={campaignData.name}
                            onChange={(e) => setCampaignData({
                                ...campaignData,
                                name: e.target.value
                            })}
                            required
                            placeholder="Enter campaign name"
                        />
                    </div>

                    <div className="form-group">
                        <label>Email Subject</label>
                        <input
                            type="text"
                            value={campaignData.subject}
                            onChange={(e) => setCampaignData({
                                ...campaignData,
                                subject: e.target.value
                            })}
                            required
                            placeholder="Enter email subject"
                        />
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>From Name</label>
                            <input
                                type="text"
                                value={campaignData.fromName}
                                onChange={(e) => setCampaignData({
                                    ...campaignData,
                                    fromName: e.target.value
                                })}
                                required
                                placeholder="Enter sender name"
                            />
                        </div>

                        <div className="form-group">
                            <label>From Email</label>
                            <input
                                type="email"
                                value={campaignData.fromEmail}
                                onChange={(e) => setCampaignData({
                                    ...campaignData,
                                    fromEmail: e.target.value
                                })}
                                required
                                placeholder="Enter sender email"
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Content</label>
                        <textarea
                            value={campaignData.content}
                            onChange={(e) => setCampaignData({
                                ...campaignData,
                                content: e.target.value
                            })}
                            required
                            rows="6"
                            placeholder="Enter email content"
                        />
                    </div>

                    <div className="form-group">
                        <label>Select Groups</label>
                        <div className="groups-select">
                            {groups.map(group => (
                                <label key={group.id} className="group-checkbox">
                                    <input
                                        type="checkbox"
                                        checked={campaignData.selectedGroups.includes(group.id)}
                                        onChange={(e) => {
                                            const newGroups = e.target.checked
                                                ? [...campaignData.selectedGroups, group.id]
                                                : campaignData.selectedGroups.filter(id => id !== group.id);
                                            setCampaignData({
                                                ...campaignData,
                                                selectedGroups: newGroups
                                            });
                                        }}
                                    />
                                    <span>{group.name}</span>
                                </label>
                            ))}
                        </div>
                    </div>

                    <button type="submit" className="create-campaign-button">
                        Create Campaign
                    </button>
                </form>
            </div>

            {selectedImage && (
                <ImageModal 
                    image={selectedImage} 
                    onClose={() => setSelectedImage(null)} 
                />
            )}
        </div>
    );
} 